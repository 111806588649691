import React, {useCallback, useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {useSetCarouselInterval} from '../../utils';

interface IMobileCarousel {
  slides: any[];
  children?: React.ReactNode;
}

export const MobileCarousel = ({slides, children}: IMobileCarousel) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const lastSlideIndex = slides.length;

  const onGoToPreviousSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return lastSlideIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === lastSlideIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === lastSlideIndex;
  }, [currentSlideIndex, lastSlideIndex]);

  const [isSlideshowPaused, setIsSlideshowPaused] = useState(false);

  useSetCarouselInterval({
    isSlideshowPaused,
    currentSlideIndex,
    lastSlideIndex: lastSlideIndex,
    onSetCurrentSlideIndex: setCurrentSlideIndex,
    time: 2900,
  });

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
    onTap: () => {
      setIsSlideshowPaused(true);
    },
  });

  return (
    <div className={`lg:hidden relative`} {...handlers}>
      <div className="overflow-hidden ">
        <div
          className={`flex transition ease-out duration-200`}
          style={{
            transform: `${
              isLastSlide ? '' : `translatex(-${currentSlideIndex * 100}%)`
            }`,
          }}>
          {children}
        </div>
        <div className="pt-8 flex justify-center gap-2 w-full">
          {slides.map((s, i) => (
            <div
              onClick={() => {
                setCurrentSlideIndex(i);
              }}
              className={`w-10 h-1 bg-primaryred ${
                i === currentSlideIndex ? 'bg-opacity-100' : 'bg-opacity-40'
              } `}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
