import * as React from 'react';
import {ToggleBox} from './ToggleBox';
import {thinMargins} from '../../constants';
import {Faq} from '../../types';

interface IFaqBox {
  faqs: Faq[];
}

export const FaqBox = ({faqs}: IFaqBox) => {
  return (
    <div className={`gap-5 ${thinMargins} pb-6 lg:pb-10`}>
      <h2 className="text-h2mob lg:text-h2 text-black font-title text-center pb-12">
        FAQs
      </h2>
      {faqs.map((faq, i) => (
        <ToggleBox key={i} title={faq.question} description={faq.answer} />
      ))}
    </div>
  );
};
