import * as React from 'react';
import {thinMargins} from '../../constants';
import {useState} from 'react';

export const ContactForm = ({title}: {title: string}) => {
  const [showThanks, setShowThanks] = useState(false);

  return (
    <div className={`pt-32 pb-8 lg:pb-16 text-black ${thinMargins} `}>
      <h3 className="text-h3mob lg:text-h3 pb-6 lg:pb-10 font-title">
        {title}
      </h3>
      <iframe name="stopredirect" id="stopredirect" className="hidden"></iframe>
      <form
        action="https://europe-west2-luna-life-prod.cloudfunctions.net/getInTouch"
        method="GET"
        target="stopredirect"
        id="contactForm"
        onSubmit={() => {
          setShowThanks(true);
        }}>
        <div className="flex flex-col lg:flex-row gap-5 w-full">
          <input
            type="text"
            id="key"
            name="key"
            defaultValue="t425638l"
            className="hidden"
          />
          <div className="flex flex-col gap-3 lg:w-1/3">
            <label
              htmlFor="firstName"
              className="text-smallbodymob lg:text-smallbody font-poppins">
              first name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
          <div className="flex flex-col gap-3 lg:w-1/3">
            <label
              htmlFor="lastName"
              className="text-smallbodymob lg:text-smallbody font-poppins">
              last name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
          <div className="flex flex-col gap-3 lg:w-1/3">
            <label
              htmlFor="email"
              className="text-smallbodymob lg:text-smallbody font-poppins">
              email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-8">
          <label
            htmlFor="msg"
            className="text-smallbodymob lg:text-smallbody font-poppins">
            message:
          </label>
          <textarea
            id="msg"
            name="message"
            className="rounded-[1.5rem] min-h-[14.25rem] p-6"
            required={true}
            minLength={5}></textarea>
        </div>
        <div
          id="thank_you"
          className={`font-poppins text-semibold ${
            showThanks ? 'block pt-6 text-center' : 'hidden'
          }`}>
          <p>thanks, we&apos;ll be in touch soon!</p>
        </div>
        <button
          type="submit"
          className={`${
            showThanks ? 'mt-4' : 'mt-10'
          } text-black font-semibold text-smallbody hover:text-hoverblack w-fit py-2 px-6 rounded-3xl bg-sandwisp hover:bg-[#EBD384]`}
          id="submit">
          Send
        </button>
      </form>
    </div>
  );
};

export const CompactContactForm = ({title}: {title: string}) => {
  const [showThanks, setShowThanks] = useState(false);

  return (
    <div className="text-black">
      <h3 className="text-subtitlelargemob lg:text-subtitlelarge pb-6 font-title">
        {title}
      </h3>
      <iframe name="stopredirect" id="stopredirect" className="hidden"></iframe>
      <form
        action="https://europe-west2-luna-life-prod.cloudfunctions.net/getInTouch"
        method="GET"
        target="stopredirect"
        id="contactForm"
        onSubmit={() => {
          setShowThanks(true);
        }}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-full">
          <input
            type="text"
            id="key"
            name="key"
            defaultValue="t425638l"
            className="hidden"
          />
          <div className="flex flex-col gap-3 ">
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="first name"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
          <div className="flex flex-col gap-3 ">
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="last name"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
          <div className="flex flex-col gap-3 ">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="email"
              className="rounded-[1rem] h-[3.5rem] p-6"
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-8">
          <textarea
            id="msg"
            name="message"
            className="rounded-[1.5rem] min-h-[10rem] p-6"
            placeholder="message"
            required={true}
            minLength={5}></textarea>
        </div>
        <div
          id="thank_you"
          className={`font-poppins text-semibold ${
            showThanks ? 'block pt-6 text-center' : 'hidden'
          }`}>
          <p>Thanks, we&apos;ll be in touch soon!</p>
        </div>
        <button
          type="submit"
          className={`${
            showThanks ? 'mt-4' : 'mt-8'
          } text-black font-semibold text-smallbody hover:text-hoverblack w-fit py-2 px-6 rounded-3xl bg-sandwisp hover:bg-[#EBD384]`}
          id="submit">
          Send
        </button>
      </form>
    </div>
  );
};
