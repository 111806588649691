import * as React from 'react';
import Markdown from 'react-markdown';

import {colors} from '../../theme';
import {Icon} from '../../components/common/Icon';

interface ToggleBoxProps {
  title: string;
  description: string;
}

export const ToggleBox = ({title, description}: ToggleBoxProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className="cursor-pointer bg-white text-black py-4 lg:py-6 px-6 lg:px-8 rounded-[2rem] mb-6 lg:mb-8"
      onClick={() => {
        setIsOpen(!isOpen);
      }}>
      <div className="flex justify-between items-center">
        <p className="text-smallbodymob lg:text-largebody font-title">
          {title}
        </p>
        <div className="w-fit">
          <Icon
            iconName={isOpen ? 'chevron-up' : 'chevron-down'}
            color={colors.black}
            height={28}
            width={28}
          />
        </div>
      </div>
      <div
        className={`text-smallbodymob lg:text-largebody pt-4 ${
          isOpen ? 'block' : 'hidden'
        }`}>
        <Markdown
          components={{
            p: ({node, ...props}) => <p {...props} className="pb-2" />,
            ul: ({node, ...props}) => (
              <ul {...props} className="list-disc ml-4 py-2" />
            ),
            li: ({node, ...props}) => <li {...props} className="mb-2" />,
          }}>
          {description}
        </Markdown>
      </div>
    </div>
  );
};
