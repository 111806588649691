import React from 'react';
import {thinMargins} from '../../constants';
import {Button} from '../../components';
import {MobileCarousel} from '../../components/common/MobileCarousel';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {ParentPayStep} from '../../types';
import {PremiumSectionContainer} from './PremiumSectionContainer';
import {PremiumProductButton} from './PremiumProduct';

interface IPhoneTextBlock {
  count: number;
  title: string;
  descr: string;
  img: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

const PhoneTextBlock = ({count, title, descr, img}: IPhoneTextBlock) => {
  return (
    <div className="flex flex-col gap-5 px-2">
      <div className="flex gap-5 justify-center lg:justify-start">
        <div className="flex flex-col justify-between gap-3">
          <div className="h-12 w-12 bg-relationships rounded-full text-center content-center font-semibold text-subtitlesmall">
            {count}
          </div>
          <p
            className="rotate-180 font-bold text-subtitlelargemob xl:text-subtitlelarge"
            style={{writingMode: 'vertical-rl'}}>
            {title}
          </p>
        </div>

        <div className="w-56">
          <GatsbyImage
            alt={img.alt || 'an image of luna parent pay'}
            image={img.gatsbyImageData}
            className="h-fit"
            draggable={false}
          />
        </div>
      </div>
      <div>
        <p className="text-center ">{descr}</p>
      </div>
    </div>
  );
};

interface IParentPay {
  title: string;
  subtitle: string;
  parentPaySteps: ParentPayStep[];
}

export const ParentPay = ({title, subtitle, parentPaySteps}: IParentPay) => {
  const getNowPlan = 'Annual';
  return (
    <div className={`text-black ${thinMargins}`}>
      <h2 className="text-h3mob md:text-h3 font-title pb-6 text-center">
        {title}
      </h2>
      <p className="text-largebodymob md:text-largebody pb-6 md:pb-10 text-center">
        {subtitle}
      </p>
      <div className="flex-row  hidden lg:flex">
        {parentPaySteps.map((s, i) => (
          <div key={i} className={`w-1/${parentPaySteps.length}`}>
            <PhoneTextBlock
              count={i + 1}
              title={s.title}
              descr={s.description}
              img={s.img}
            />
          </div>
        ))}
      </div>
      <MobileCarousel slides={parentPaySteps}>
        {parentPaySteps.map((s, i) => (
          <div className="min-w-full" key={i}>
            <div className="justify-center flex">
              <PhoneTextBlock
                count={i + 1}
                title={s.title}
                descr={s.description}
                img={s.img}
              />
            </div>
          </div>
        ))}
      </MobileCarousel>
      <div className="flex justify-center pt-10">
        <PremiumSectionContainer backTo="/premium">
          {({products, creatingCheckout, onPressProduct}) => (
            <div className={thinMargins}>
              {!products.find(p => p.name === getNowPlan) && (
                <Button
                  label="Download now"
                  linkTo="download"
                  type="tertiary"
                />
              )}
              {products.map(p => {
                if (p.name !== getNowPlan) {
                  return null;
                }
                const isBuying = p.stripePriceLookupKey === creatingCheckout;
                return (
                  <div
                    key={p.id}
                    className={'md:flex-1 xl:flex-none xl:w-[360px]'}>
                    <PremiumProductButton
                      isBuying={isBuying}
                      ctaBuy={'Get luna now'}
                      onClickBuy={() => {
                        onPressProduct({
                          lookup_key: p.stripePriceLookupKey ?? '',
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </PremiumSectionContainer>
      </div>
    </div>
  );
};
