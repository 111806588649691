import React from 'react';

import {Button, Link} from '../../components';

interface IPolicyForm {
  url: string;
}

export const PolicyForm = ({url}: IPolicyForm) => {
  const [formSubmissionState, setFormSubmissionState] = React.useState<
    null | 'submitting' | 'success' | 'error'
  >(null);

  const formStyling = 'rounded-[8px] h-[3.5rem] p-6 border border-lightgrey';

  if (formSubmissionState === 'success') {
    return (
      <div className="flex justify-center pt-10">
        <Button label="view policy" externalLinkUrl={url} />
      </div>
    );
  }
  if (formSubmissionState === 'error') {
    return (
      <div className="justify-center md:justify-start">
        <p>
          😅 oops, looks like something went wrong. refresh the page to try
          again or email us and we&apos;ll send you a copy of our policy!
        </p>
        <Link href="mailto:hello@weareluna.app">
          <p className="font-title hover:text-sandwisp pt-2">
            hello@weareluna.app
          </p>
        </Link>
      </div>
    );
  }

  return (
    <form
      name="safeguarding-policy"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={e => {
        e.preventDefault();
        const myForm = e.target as any;
        const formData = new FormData(myForm) as any;
        if (formSubmissionState !== 'submitting') {
          setFormSubmissionState('submitting');
          fetch('/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData).toString(),
          })
            .then(() => {
              setFormSubmissionState('success');
            })
            .catch(() => {
              setFormSubmissionState('error');
            });
        }
      }}>
      <div className="flex flex-col gap-5 w-full pt-8">
        <input type="hidden" name="form-name" value="safeguarding-policy" />
        <div className="flex flex-col gap-4">
          <input
            type="text"
            name="name"
            placeholder="Name"
            className={formStyling}
            minLength={1}
            required={true}
          />
        </div>
        <div className="flex flex-col gap-3">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className={formStyling}
            minLength={5}
            required={true}
          />
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <button
          type="submit"
          className="bg-sandwisp py-3 px-6 rounded-3xl block text-black font-semibold text-smallbody">
          Get access
        </button>
      </div>
    </form>
  );
};
