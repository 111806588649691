import * as React from 'react';
import {Icon, Link, Tag} from '../../components';
import {colors} from '../../theme';
import {LearnArticle} from '../../types';
import {GatsbyImage} from 'gatsby-plugin-image';

interface IArticleCard extends LearnArticle {
  isFullWidth?: boolean;
  isParentsArticle?: boolean;
  slug?: string;
}

export const ArticleCard = ({
  title,
  subtitle,
  image,
  categoryTitle,
  categories,
  slug,
  isFullWidth = false,
  isParentsArticle = false,
}: IArticleCard) => {
  const width = isFullWidth
    ? 'w-full'
    : 'min-w-[18.5rem] w-[18.5rem] lg:min-w-[22rem] lg:w-[22rem]';
  return (
    <div
      className={`${width} rounded-[2rem] text-black relative h-full border-lightgrey border-[0.76px]`}>
      <div className="rounded-t-[2rem] h-[60%] lg:h-[65%]">
        <div className="absolute top-4 left-4 z-10 flex flex-wrap flex-row gap-2 pr-4">
          {categories &&
            categories
              .slice(0, 2)
              .map((c, i) => (
                <Tag color={c.color.hex} title={c.title} key={i} />
              ))}
        </div>

        {image && (
          <GatsbyImage
            alt={image.alt || `an image of ${title} article`}
            image={image.gatsbyImageData}
            className="h-full w-full object-cover rounded-t-[2rem]"
            draggable={false}
          />
        )}
      </div>

      <div className="bg-white h-[40%] lg:h-[35%] rounded-b-[2rem] px-4 flex flex-col justify-between">
        <div className="pt-4">
          <p className="text-largebodymob lg:text-largebody font-semibold ">
            {title}
          </p>
          <p className="text-smallbodymov lg:text-smallbody truncate pt-1">
            {subtitle}
          </p>
        </div>
        <Link
          articleLink={{
            isParents: isParentsArticle,
            categoryTitle,
            articleTitle: title,
            articleSlug: slug,
          }}>
          <div className="flex text-primaryred py-4 justify-end hover:font-semibold">
            <p>view</p>
            <Icon iconName={'right'} color={colors.primaryred} />
          </div>
        </Link>
      </div>
    </div>
  );
};
