import * as React from 'react';

export interface IExplainerFact {
  readonly emoji?: string | null;
  readonly title: string | null;
  readonly text: string | null;
  isColumn?: boolean;
}

export const ExplainerFact = ({
  emoji,
  title,
  text,
  isColumn,
}: IExplainerFact) => {
  return (
    <div
      className={`flex sm:flex-row lg:flex-col justify-center items-center lg:items-start md:justify-start text-left ${!isColumn && 'lg:w-1/3'}`}>
      {emoji && <p className="text-h3 pr-8 lg:pb-2">{emoji}</p>}
      <div>
        <h2
          className={`text-largebodymob md:subtitlesmall font-title ${isColumn ? 'pb-1' : 'pb-4 lg:pb-6'} `}>
          {title}
        </h2>
        <p className="text-smallbodymob lg:text-smallbody">{text}</p>
      </div>
    </div>
  );
};

export const ExplainerFacts = ({
  facts,
}: {
  facts: readonly (IExplainerFact | null)[] | null;
}) => {
  return (
    <div className="text-black flex justify-evenly flex-col lg:flex-row gap-7">
      {facts &&
        facts.map((f, i) => (
          <ExplainerFact
            key={i}
            emoji={f?.emoji}
            title={f?.title || ''}
            text={f?.text || ''}
          />
        ))}
    </div>
  );
};

export const ExplainerFactsColumn = ({
  facts,
}: {
  facts: readonly (IExplainerFact | null)[] | null;
}) => {
  return (
    <div className="text-black flex justify-evenly flex-col gap-7">
      {facts &&
        facts.map((f, i) => (
          <ExplainerFact
            key={i}
            emoji={f?.emoji}
            title={f?.title || ''}
            text={f?.text || ''}
            isColumn={true}
          />
        ))}
    </div>
  );
};
