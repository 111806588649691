import React, {useCallback, useMemo, useState} from 'react';
import {Button} from '../../components';
import {useScreenSize} from '../../utils/useScreenSize';
import faith from '../../images/work/faith-in-nature.png';
import start from '../../images/work/clear-start.jpg';
import flo from '../../images/work/brand-003.png';
import dermalogica from '../../images/work/dermalogica.jpg';
import fluxies from '../../images/work/fluxies.png';
import indu from '../../images/work/indu.jpg';
import {isBrowser} from '../../utils/isBrowser';
import {wideMargins} from '../../constants';
import {useSwipeable} from 'react-swipeable';
import {useSetCarouselInterval} from '../../utils/setCarouselInterval';

const partnershipsList = [
  {
    brandName: 'Start Clear',
    img: start,
  },
  {
    brandName: 'Flo',
    img: flo,
  },
  {
    brandName: 'fluxies',
    img: fluxies,
  },
  {
    brandName: 'Faith in Nature',
    img: faith,
  },
  {
    brandName: 'indu',
    img: indu,
  },
  {
    brandName: 'dermalogica',
    img: dermalogica,
  },
];

export const Partnerships = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  const screenSize = useScreenSize();
  const lastSlideIndex = partnershipsList.length;
  const startSlideIndex = 0;

  const pageWidth = isBrowser ? window.innerWidth : 0;

  const setup = useMemo((): {
    mainCardSize: number;
    cardMarginCalc: number;
  } => {
    if (screenSize === 'extra-large' || screenSize === 'xx-large') {
      return {
        mainCardSize: 23.25,
        cardMarginCalc: pageWidth / 2 - 176,
      };
    } else if (screenSize === 'large') {
      return {
        mainCardSize: 23.25,
        cardMarginCalc: pageWidth / 2 - 176,
      };
    } else {
      return {
        mainCardSize: 19.25,
        cardMarginCalc: pageWidth / 2 - 144,
      };
    }
  }, [screenSize, pageWidth]);

  const [currentSlideIndex, setCurrentSlideIndex] =
    useState<number>(startSlideIndex);

  const [isSlideshowPaused, setIsSlideshowPaused] = useState(false);

  const onGoToPreviousSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return lastSlideIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === lastSlideIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === lastSlideIndex;
  }, [currentSlideIndex, lastSlideIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  useSetCarouselInterval({
    isSlideshowPaused,
    currentSlideIndex,
    lastSlideIndex,
    onSetCurrentSlideIndex: setCurrentSlideIndex,
    time: 2200,
  });

  return (
    <div>
      <div className={`${wideMargins} text-black text-center flex flex-col`}>
        <h2 className="text-h3mob lg:text-h3 font-title ">{title}</h2>
        <p className="text-largebodymob lg:text-largebody pt-6">{subtitle}</p>
        <div className="pt-8 pb-8 lg:pb-12 min-w-fit h-[3.5rem] mx-auto">
          <Button
            label="Contact us"
            type="primary"
            externalLinkUrl="mailto:hello@weareluna.app"
          />
        </div>
      </div>
      <div {...handlers}>
        <div className="overflow-x-hidden">
          <div
            className={`flex items-center transition ease-out duration-700 gap-5 h-[26rem] my-8`}
            style={{
              marginLeft: setup.cardMarginCalc,
              transform: `${
                isLastSlide
                  ? ''
                  : `translatex(-${currentSlideIndex * setup.mainCardSize}rem)`
              }`,
            }}>
            {partnershipsList.map((p, i) => (
              <div
                key={i}
                className={`${
                  i === currentSlideIndex
                    ? 'animate-growCard h-[26rem]'
                    : 'h-[22.5rem]'
                } min-w-[18rem] lg:min-w-[22rem] w-[18rem] lg:w-[22rem]`}>
                <img
                  src={p.img}
                  alt={`An image of ${p.brandName}`}
                  className={`rounded-[2rem] object-cover object-center min-h-full max-h-full w-full `}
                  draggable={false}
                  key={i}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
