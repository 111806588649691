import React from 'react';

import {Icon, Link} from '../components';
import {thinMargins} from '../constants';
import {
  StructuredText,
  StructuredTextDocument,
} from 'react-datocms/structured-text';

export const CategoryHeader = ({
  context,
  categoryTitle,
  categoryDescr,
  isParents = false,
}: {
  context: 'Learn' | 'Ask';
  categoryTitle: string;
  categoryDescr?: StructuredTextDocument;
  isParents?: boolean;
}) => {
  return (
    <div className={`pt-24 lg:pt-28 pb-6 lg:pb-10 bg-background`}>
      <div className={`${thinMargins}`}>
        {isParents ? (
          <Link linkTo="parents/guides">
            <div className="flex items-center gap-4 pb-2">
              <Icon iconName={'left'} height={40} width={40} />
              <p className="text-smallbodymob lg:text-smallbody hover:underline">
                Parent guides
              </p>
            </div>
          </Link>
        ) : (
          <Link linkTo={context === 'Learn' ? 'articles' : 'questions'}>
            <div className="flex items-center gap-4 pb-2">
              <Icon iconName={'left'} height={40} width={40} />
              <p className="text-smallbodymob lg:text-smallbody hover:underline">
                {context === 'Learn' ? 'Article library' : 'Question library'}
              </p>
            </div>
          </Link>
        )}
        <h1 className="text-h2mob lg:text-h2 font-title pt-6">
          {categoryTitle}
        </h1>
        {categoryDescr && (
          <p className="text-smallbodymob lg:text-smallbody pt-4">
            <StructuredText data={categoryDescr} />
          </p>
        )}
      </div>
    </div>
  );
};
