import * as React from 'react';
import apple from '../../images/apple-nav.png';
import android from '../../images/android-nav.png';
import {Link} from '../../components/common';
import {Page} from '../../types';

interface INavDownloadCTA {
  className?: string;
}

export const NavDownloadCTA = ({className}: INavDownloadCTA) => {
  const style = className
    ? `${className}`
    : 'bg-sandwisp ml-6 justify-center hover:bg-[#EBD384]';
  return (
    <Link
      linkTo="download"
      className={`w-28 rounded-[1.8rem] py-2 flex items-center ${style}`}>
      <img
        src={apple}
        alt="apple logo linking to the dowload page"
        className="h-[1.5rem]"
      />
      <p className="text-black leading-none px-2">|</p>
      <img
        src={android}
        alt="android logo linking to the dowload page"
        className="h-[1.5rem]"
      />
    </Link>
  );
};

export const NavDownloadCTAMob = ({
  color,
  ctaCopy,
  linkTo,
}: {
  color?: string;
  ctaCopy: string;
  linkTo: Page;
}) => {
  return (
    <Link
      linkTo={linkTo}
      className={`w-1/3 md:w-18 ${color ? color : 'bg-sandwisp'} rounded-[1.8rem] md:hidden hover:bg-[#EBD384]`}>
      <div className="justify-center items-center px-6 py-3 md:py-2 flex">
        <p className="text-black leading-none px-2 block md:hidden text-smallbody font-semibold">
          {ctaCopy}
        </p>
      </div>
    </Link>
  );
};
