import React, {useCallback, useMemo, useState} from 'react';

import {Button, Link} from '../../components';
import {CookieConsentStatus} from '../../module/localStorage';

const defaultCookie = 'essential';

const Cookie = ({
  title,
  descr,
  disabled = false,
  position,
  onToggle,
}: {
  title: string;
  descr: string;
  disabled?: boolean;
  position: number;
  onToggle?: () => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback(() => {
    if (!disabled) {
      setIsChecked(!isChecked);
    }
    onToggle && onToggle();
  }, [isChecked, disabled]);

  return (
    <div
      className={`py-3 ${position === 0 ? 'border-y-2 border-y-divider' : 'border-b-2 border-b-divider'}`}>
      <div className="pb-2 flex flex-row justify-between items-center">
        <h3 className="font-semibold text-left">{title}</h3>
        <div>
          <>
            <label
              className={`flex ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none items-center`}>
              <div className="relative block h-8 w-14 rounded-full bg-[#E5E7EB]">
                <input
                  type="checkbox"
                  checked={disabled ? true : isChecked}
                  onChange={handleCheckboxChange}
                  className="sr-only"
                />
                <div
                  className={`box block h-8 w-14 rounded-full ${
                    disabled
                      ? 'bg-gender'
                      : isChecked
                        ? 'bg-alertGreen'
                        : 'bg-dark'
                  }`}></div>
                <div
                  className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                    disabled
                      ? 'translate-x-full'
                      : isChecked
                        ? 'translate-x-full'
                        : ''
                  }`}></div>
              </div>
            </label>
          </>
        </div>
      </div>
      <div>
        <p className="text-caption text-left">{descr}</p>
      </div>
    </div>
  );
};

export const CookiePopup = ({
  onAcceptAllCookies,
  onClose,
  onSetPreferences,
}: {
  onAcceptAllCookies: () => void;
  onClose: () => void;
  onSetPreferences: (preferences: CookieConsentStatus) => void;
}) => {
  const [analyticsStatus, setAnalyticsStatus] = useState(false);
  const [marketingStatus, setMarketingStatus] = useState(false);
  const [functionalStatus, setFunctionalStatus] = useState(false);

  const cookiePreferences = useMemo(() => {
    const cookies: CookieConsentStatus = [defaultCookie];
    if (analyticsStatus) {
      cookies.push('analytics');
    }
    if (marketingStatus) {
      cookies.push('marketing');
    }
    if (functionalStatus) {
      cookies.push('functional');
    }
    return cookies;
  }, [analyticsStatus, marketingStatus, functionalStatus]);

  const onSavePreferences = useCallback(() => {
    onSetPreferences(cookiePreferences);
  }, [cookiePreferences]);

  const cookieChoices = [
    {
      title: 'Essential cookies',
      descr:
        'These cookies enable core and basic website functionality and cannot be switched off; for example, your cookie consent options are stored so you are not repeatedly asked to consent. You may disable these by changing your browser settings, but this may affect how the website functions.',
      disabled: true,
    },
    {
      title: 'Functional cookies',
      descr:
        'These cookies and similar technologies enable us to provide enhanced functionality and personalisation. For example, your preferences are stored so they do not need to be repeatedly adjusted.',
      onToggle: () => {
        setFunctionalStatus(!functionalStatus);
      },
    },
    /* This wording refers to google analytics, and is similar to this: https://www.cookieyes.com/blog/cookie-consent-exemption-for-strictly-necessary-cookies/ */
    {
      title: 'Analytics cookies',
      descr:
        'These cookies collect information about how you use our services. Our site uses this information to understand how visitors interact with our website, providing metrics such as number of visitors, traffic source, etc.',
      onToggle: () => {
        setAnalyticsStatus(!analyticsStatus);
      },
    },
    /* This wording refers to the meta pixel, and was lifted from here: https://www.leicestershire.gov.uk/cookies */
    {
      title: 'Marketing cookies',
      descr:
        "These cookies helps us with measure how effective our Meta (Facebook) adverts are. We’re then able to use this information to improve our marketing. Using a Meta (Facebook) Pixel doesn't allow us to identify any individuals.",
      onToggle: () => {
        setMarketingStatus(!marketingStatus);
      },
    },
  ];

  return (
    <div>
      <div className="text-center">
        <p className="text-h3mob pb-2 lg:pb-4">Cookie preferences</p>
        <p className="text-smallbodymob pt-2">
          Our website uses cookies and similar technologies, for example for
          analytics to allow us to understand how you interact with our
          services, improve our product, and to measure the effectiveness of
          ads. For further detail on how we use cookies and similar technologies
          and how we process your personal data, please see our{' '}
          <Link linkTo="privacy-policy" className="text-hotcalypso">
            Privacy Policy.{' '}
          </Link>
        </p>
        <p className="text-smallbodymob pt-3">
          By clicking &quot;Accept all&quot;, you consent to the use of all
          cookies and similar technologies and above-mentioned purposes, as well
          as to the processing of your data in connection with this. You can
          choose which type of cookies you&apos;d like to accept using the
          options below.
        </p>
        <div className="mt-4">
          {cookieChoices.map((cookie, i) => {
            return <Cookie key={i} {...cookie} position={i} />;
          })}
        </div>

        <div className="flex flex-row justify-between sm:flex-row sm:justify-center sm:min-w-fit py-3 gap-4">
          <div className="sm:w-auto">
            <Button
              label="Accept all"
              onClick={() => {
                onAcceptAllCookies();
                onClose();
              }}
              fullWidth
            />
          </div>
          <div className="sm:w-auto">
            <Button
              label="Save choices"
              onClick={() => {
                onSavePreferences();
                onClose();
              }}
              fullWidth
              type="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
