import * as React from 'react';
import {Icon, Link} from '../../components';
import {colors} from '../../theme';

interface IArticleBreadcrumbs {
  categoryTitle: string;
  articleTitle: string;
  context?: 'teen' | 'parent';
}

const RightArrow = () => {
  return <Icon iconName={'right'} fill={colors.background} />;
};

export const ArticleBreadcrumbs = ({
  categoryTitle,
  articleTitle,
  context = 'teen',
}: IArticleBreadcrumbs) => {
  const isParent = context === 'parent';

  return (
    <div className="flex flex-wrap pb-6 lg:pb-10 gap-1 text-caption items-center">
      <div className="flex w-max gap-1 items-center">
        <Link linkTo={context === 'teen' ? 'home' : 'parents'}>
          {context === 'teen' ? 'Home' : 'Parents'}
        </Link>
        <RightArrow />
      </div>
      <div className="flex w-max gap-1 items-center">
        <Link linkTo={context === 'teen' ? 'articles' : 'parents/guides'}>
          {context === 'teen' ? 'Article library' : 'Parent guides'}
        </Link>
        <RightArrow />
      </div>
      <div className="flex w-max gap-1 items-center">
        <Link
          articleLink={{
            isParents: isParent,
            categoryTitle: categoryTitle,
          }}>
          {categoryTitle}
        </Link>
        <RightArrow />
      </div>
      <div className="w-max">
        <Link
          articleLink={{
            categoryTitle: categoryTitle,
            articleTitle: articleTitle,
            isParents: isParent,
          }}>
          {articleTitle}
        </Link>
      </div>
    </div>
  );
};
