import React, {useState} from 'react';

import {Link, Modal} from '../../components';
interface IPopUp {
  isPopUpOpen: boolean;
  title: string;
  descr: string;
  buttonCta: string;
  onClose: () => void;
}
export const PopUp = ({
  isPopUpOpen,
  title,
  descr,
  buttonCta,
  onClose,
}: IPopUp) => {
  const [showThanks, setShowThanks] = useState(false);

  const segment = 'web_popup_parent';

  return (
    <div>
      <Modal hasCloseBtn={true} isOpen={isPopUpOpen} onClose={onClose} isDark>
        <div className="text-center">
          <h1 className="text-subtitlelargemob lg:text-subtitlelarge pb-4 lg:pb-6 font-bold">
            {title}
          </h1>
          <p className="text-center text-black text-smallbody font-semibold font-inter pb-2">
            {descr}
          </p>
          <form
            action="https://europe-west2-luna-life-prod.cloudfunctions.net/joinMailingList"
            method="GET"
            target="stopredirect"
            id="newsletterSignUpForm"
            onSubmit={() => {
              setShowThanks(true);
            }}>
            <div className="w-full">
              <iframe
                name="stopredirect"
                id="stopredirect"
                className="hidden"></iframe>
              <div className="mt-6">
                <input
                  type="text"
                  id="key"
                  name="key"
                  defaultValue="t425638l"
                  className="hidden"
                />
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  className="rounded-lg h-[3.5rem] p-6 font-inter border-none w-full"
                  required={true}
                />
                <input
                  type="text"
                  id="segments"
                  name="segments"
                  value={segment}
                  className="hidden"
                />
              </div>
              <button
                type="submit"
                className={`bg-sandwisp hover:bg-[#EBD384] mt-5 text-black font-semibold text-smallbody hover:text-hoverblack w-fit py-2 px-6 rounded-3xl `}
                id="submit">
                {showThanks ? 'Thank you!' : buttonCta}
              </button>
            </div>
          </form>
          <p className="text-caption pt-6">
            By signing up, you are agreeing that we can use your email address
            to market to you. You can unsubscribe from marketing emails at any
            time by using the link in our emails. For more information, please
            review our{' '}
            <Link linkTo="privacy-policy" className="text-hotcalypso">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </Modal>
    </div>
  );
};
