import React, {useCallback, useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {AskLunaQuestion} from '../../types';
import {Button, Icon, Link} from '../../components/common';
import {colors} from '../../theme';
import {thinMargins} from '../../constants';
import {useScreenSize} from '../../utils/useScreenSize';
import {usePageWidth} from '../../utils';
import {QuestionCard} from './QuestionCard';

export interface IAskSampler {
  questions: AskLunaQuestion[];
  title: string;
  subtitle?: string;
  context?: 'questionPage' | 'mainSite';
}

export const AskSampler = ({
  questions,
  title,
  subtitle,
  context = 'mainSite',
}: IAskSampler) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const pageWidth = usePageWidth();
  const screenSize = useScreenSize();

  const setup = useMemo((): {
    transformSize: string;
    marginLeft: number;
    lastSlideIndex: number;
  } => {
    if (screenSize === 'xx-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 26}rem)`,
        marginLeft: (pageWidth - 1280) / 2,
        lastSlideIndex:
          questions.length > 1 ? questions.length - 2 : questions.length,
      };
    } else if (screenSize === 'extra-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 26}rem)`,
        marginLeft: 128,
        lastSlideIndex:
          questions.length > 1 ? questions.length - 1 : questions.length,
      };
    } else if (screenSize === 'large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 26}rem)`,
        marginLeft: 128,
        lastSlideIndex:
          questions.length > 1 ? questions.length - 1 : questions.length,
      };
    } else if (screenSize === 'medium') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 18.25}rem)`,
        marginLeft: 32,
        lastSlideIndex:
          questions.length > 1 ? questions.length - 1 : questions.length,
      };
    } else {
      return {
        transformSize: `translatex(-${currentSlideIndex * 18.25}rem)`,
        marginLeft: 32,
        lastSlideIndex: questions.length,
      };
    }
  }, [screenSize, currentSlideIndex, pageWidth]);

  const onGoToPreviousSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return setup.lastSlideIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === setup.lastSlideIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === setup.lastSlideIndex;
  }, [currentSlideIndex, setup.lastSlideIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  const isQuestionPage = context === 'questionPage';

  return (
    <div className="relative">
      <div
        className={`max-w-screen-xl flex ${
          context === 'mainSite' ? ' flex-col pb-8 lg:pb-10' : 'flex-row pb-6'
        } lg:flex-row justify-center md:items-center lg:items-start md:text-center lg:text-start ${thinMargins} lg:mx-32 `}>
        <div className="lg:w-2/3">
          <h3
            className={`${
              isQuestionPage
                ? 'text-subtitlelargemob lg:text-subtitlelarge'
                : 'text-h3mob lg:text-h3'
            } text-black font-title `}>
            {title}
          </h3>
          {subtitle && (
            <p
              className={`text-largebodymob lg:text-largebody text-black pt-2 lg:pt-6`}>
              {subtitle}
            </p>
          )}
        </div>
        {context === 'mainSite' ? (
          <div className="hidden lg:block lg:ml-auto lg:mr-0 mt-auto mb-0">
            <Button label={'View more questions'} linkTo="questions" />
          </div>
        ) : (
          <div className=" ml-auto mr-0 mt-auto mb-0 min-w-fit">
            <Button
              questionLink={{
                categoryTitle: title,
              }}
              label={'View all'}
            />
          </div>
        )}
      </div>
      <div
        {...handlers}
        style={{
          marginLeft: `${setup.marginLeft}px`,
        }}>
        <div className="overflow-hidden">
          <div
            className={`flex transition ease-out duration-500 gap-3 lg:gap-5`}
            style={{
              transform: `${isLastSlide ? '' : `${setup.transformSize}`}`,
            }}>
            {questions.map((q, i) => (
              <QuestionCard
                key={i}
                question={q.question || ''}
                categories={q.categories}
                categoryTitle={q.categoryTitle}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`absolute top-[55%] w-full hidden lg:flex lg:justify-between`}
        style={{
          paddingLeft: `${setup.marginLeft - 100}px`,
          paddingRight: `${setup.marginLeft - 100}px`,
        }}>
        <button onClick={onGoToPreviousSlide}>
          <Icon iconName={'left'} fill={colors.white} height={75} width={75} />
        </button>
        <button onClick={onGoToNextSlide}>
          <Icon iconName={'right'} fill={colors.white} height={75} width={75} />
        </button>
      </div>
      <div className="flex lg:hidden justify-center pt-6">
        {context === 'mainSite' && (
          <Button label={'View more questions'} linkTo="questions" />
        )}
      </div>
    </div>
  );
};
