import * as React from 'react';

interface ICommunityFigure {
  emoji: string;
  number: number;
  text: string;
}

export const CommunityFigure = ({emoji, number, text}: ICommunityFigure) => {
  return (
    <div className="flex justify-center sm:flex-row md:flex-col items-center">
      <p className="text-h3 pr-5 md:pr-0">{emoji}</p>
      <div className="text-center">
        <h1 className="text-h2 md:text-h1 font-title">
          {number.toLocaleString()}
        </h1>
        <p className="text-smallbody md:text-largebody font-semibold">{text}</p>
      </div>
    </div>
  );
};

export const CommunityFigures = ({stats}: {stats: ICommunityFigure[]}) => {
  return (
    <div className="text-black flex pt-36 justify-evenly flex-col md:flex-row gap-7 pb-16 lg:pb-24 mx-8 lg:mx-28 xl:mx-40 2xl:mx-auto max-w-[115rem]">
      {stats &&
        stats.map((d, i) => (
          <CommunityFigure
            key={i}
            emoji={d.emoji}
            number={d.number}
            text={d.text}
          />
        ))}
    </div>
  );
};
