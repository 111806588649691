import * as React from 'react';
import tickerShape from '../../images/ticker-shape.png';

interface ITopicTickerTape {
  topicList: string[];
}

export const TopicTickerTape = ({topicList}: ITopicTickerTape) => {
  return (
    <div
      className="relative flex overflow-x-hidden text-black font-semibold"
      style={{transform: 'skewY(6deg)'}}>
      <div className="flex animate-ticker leading-none text-h3 md:text-h1 whitespace-nowrap">
        {topicList.map((topic, i) => (
          <div className="flex min-w-max" key={`topic${i}`}>
            <p className="mx-4 font-poppins">{topic.toUpperCase()}</p>
            <img
              src={tickerShape}
              className="w-10 md:w-max md:h-max"
              alt="a luna brand shape"
            />
          </div>
        ))}
      </div>

      {/* The first animation doesn't restart the loop until the last topic rolls off the page. 
      This second ticker closes the large blank gap. */}
      <div
        className="flex animate-ticker2 leading-none text-h3 md:text-h1 absolute top-0 whitespace-nowrap"
        aria-hidden="true">
        {topicList.map((topic, i) => (
          <div className="flex min-w-max" key={`topic2${i}`}>
            <p className="mx-4 font-poppins">{topic.toUpperCase()}</p>
            <img
              src={tickerShape}
              className="w-10 md:w-max md:h-max"
              alt="a luna brand shape"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
