import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  CookieConsentStatus,
  getCookieConsentStatusValue,
  getParentPopUpStatusValue,
  ParentPopUpStatus,
  setCookieConsentStatusValue,
  setParentPopUpStatusValue,
} from '../module/localStorage';
import {gaOptOut} from '../module/analytics/google';
import {enableMetaTracking} from '../module/analytics/meta';

interface IStoreContext {
  sideMenuOpen: boolean;
  showCookieConsent: boolean;
  showParentPopUp: boolean;
  onSetSideMenuOpen: (open: boolean) => void;
  onAcceptAllCookies: () => void;
  onDeclineAllCookies: () => void;
  onSetCookiePreferences: (preferences: CookieConsentStatus) => void;
  onSetParentPopUpValue: (preference: ParentPopUpStatus) => void;
}

const defaultContext: IStoreContext = {
  sideMenuOpen: false,
  showCookieConsent: false,
  showParentPopUp: false,
  onSetSideMenuOpen: () => {},
  onAcceptAllCookies: () => {},
  onDeclineAllCookies: () => {},
  onSetCookiePreferences: () => {},
  onSetParentPopUpValue: () => {},
};

const StoreContext = createContext<IStoreContext>(defaultContext);

export function StoreProvider({children}: PropsWithChildren<unknown>) {
  const [sideMenuOpen, onSetSideMenuOpen] = useState(false);

  const [cookieConsentStatus, setCookieConsentStatus] =
    useState<CookieConsentStatus | null>(getCookieConsentStatusValue());

  const [parentPopUpStatus, setParentPopUpStatus] =
    useState<ParentPopUpStatus | null>(getParentPopUpStatusValue());

  const onAcceptAllCookies = useCallback(() => {
    setCookieConsentStatus(['acceptAll']);
    setCookieConsentStatusValue(['acceptAll']);
  }, []);

  const onDeclineAllCookies = useCallback(() => {
    setCookieConsentStatus(['declineAll']);
    setCookieConsentStatusValue(['declineAll']);
  }, []);

  const onSetCookiePreferences = useCallback(
    (preferences: CookieConsentStatus) => {
      setCookieConsentStatus(preferences);
      setCookieConsentStatusValue(preferences);
    },
    [],
  );

  const onSetParentPopUpValue = useCallback((status: ParentPopUpStatus) => {
    setParentPopUpStatus(status);
    setParentPopUpStatusValue(status);
  }, []);

  React.useEffect(() => {
    if (cookieConsentStatus && Array.isArray(cookieConsentStatus)) {
      if (cookieConsentStatus.includes('acceptAll')) {
        // accept all
        enableMetaTracking();
      } else if (cookieConsentStatus.includes('declineAll')) {
        // decline all
        gaOptOut();
      } else {
        // adhere to specific preferences
        if (cookieConsentStatus.includes('marketing')) {
          enableMetaTracking();
        }
        if (!cookieConsentStatus.includes('analytics')) {
          gaOptOut();
        }
      }
    }
  }, [cookieConsentStatus, parentPopUpStatus]);

  const showCookieConsent = React.useMemo(() => {
    return cookieConsentStatus === null;
  }, [cookieConsentStatus]);

  const showParentPopUp = React.useMemo(() => {
    if (parentPopUpStatus === null) {
      if (
        cookieConsentStatus?.includes('acceptAll') ||
        cookieConsentStatus?.includes('functional')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [parentPopUpStatus, cookieConsentStatus]);

  return (
    <StoreContext.Provider
      value={{
        sideMenuOpen,
        showCookieConsent,
        showParentPopUp,
        onSetSideMenuOpen,
        onAcceptAllCookies,
        onDeclineAllCookies,
        onSetCookiePreferences,
        onSetParentPopUpValue,
      }}>
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
