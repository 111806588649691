import React from 'react';
import Markdown from 'react-markdown';
import {Button, Icon} from '../../components';
import {colors} from '../../theme';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';

type PremiumProductProps = {
  name: string;
  price: string;
  description: string;
  tag?: string;
  ctaBuy: string;
  isBuying?: boolean;
  altBackground?: boolean;
  toggleTitle?: string;
  toggleContent?: string;
  toggleImg?: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  isLifetime?: boolean;
  onClickBuy: () => void;
};

export const PremiumProduct = ({
  name,
  price,
  description,
  tag,
  ctaBuy,
  isBuying = false,
  altBackground = false,
  toggleTitle,
  toggleContent,
  toggleImg,
  isLifetime,
  onClickBuy,
}: PremiumProductProps) => {
  return (
    <div
      className={`flex flex-col p-4 rounded-[1rem] w-full  ${
        altBackground ? 'bg-sandwisp' : 'bg-background'
      }`}>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row justify-between items-center mb-3">
          <p className="uppercase">{name}</p>
          {!!tag && (
            <div className="bg-relationships rounded-lg flex items-center justify-center text-caption py-1 px-3">
              {tag}
            </div>
          )}
        </div>
        <p className="text-subtitlelarge font-semibold mb-2">{price}</p>
        <span className="text-smallbody">{description}</span>
      </div>
      <div className="flex flex-col flex-1">
        {toggleTitle && toggleContent ? (
          <SeeMore
            title={toggleTitle}
            description={toggleContent}
            image={toggleImg}
          />
        ) : (
          <div className={`${isLifetime ? 'md:pt-0 xl:pt-9' : 'md:pt-9 '} `} />
        )}
      </div>
      <div className="mt-4 flex flex-col">
        <Button
          label={ctaBuy}
          onClick={onClickBuy}
          type="accent"
          fullWidth
          submitting={isBuying}
        />
      </div>
    </div>
  );
};

interface SeeMoreProps {
  title: string;
  description: string;
  image?: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

export const SeeMore = ({title, description, image}: SeeMoreProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className="cursor-pointer text-black pt-2"
      onClick={() => {
        setIsOpen(!isOpen);
      }}>
      <div className="flex justify-between items-center">
        <p className="text-smallbodymob lg:text-smallbody font-semibold">
          {title}
        </p>
        <div className="w-fit">
          <Icon
            iconName={isOpen ? 'chevron-up' : 'chevron-down'}
            color={colors.black}
            height={28}
            width={28}
          />
        </div>
      </div>
      <div className={`text-caption pt-2 ${isOpen ? 'block' : 'hidden'}`}>
        <Markdown
          components={{
            p: ({...props}) => <p {...props} className="pb-2" />,
            ul: ({...props}) => (
              <ul {...props} className="list-disc ml-4 py-2" />
            ),
            li: ({...props}) => <li {...props} className="mb-2" />,
          }}>
          {description}
        </Markdown>
        {image && (
          <GatsbyImage
            alt={image.alt}
            image={image.gatsbyImageData}
            className="h-full w-full rounded-[2rem] z-0  max-h-[450px] content-center"
            draggable={false}
          />
        )}
      </div>
    </div>
  );
};

interface IPremiumProductButton {
  ctaBuy: string;
  isBuying?: boolean;
  onClickBuy: () => void;
}

export const PremiumProductButton = ({
  ctaBuy,
  isBuying = false,
  onClickBuy,
}: IPremiumProductButton) => {
  return (
    <div className="mt-4 flex flex-col">
      <Button
        label={ctaBuy}
        onClick={onClickBuy}
        type="tertiary"
        fullWidth
        submitting={isBuying}
      />
    </div>
  );
};
