import React, {useMemo, useState} from 'react';
import {thinMargins} from '../../constants';
import headerImage from '../../images/404-header.png';
import {Segment} from '../../types';
import {Tracker} from '../../module/analytics';

interface INewsletterSignup {
  title: string;
  subtitle?: string;
  generalCheckbox?: string;
  parentCheckbox?: string;
  disclaimer: string;
  bgColor?: string;
  contrast: boolean;
  isParentDefault?: boolean;
}

export const NewsletterSignup = ({
  title,
  subtitle,
  generalCheckbox,
  parentCheckbox,
  disclaimer,
  bgColor,
  contrast = false,
  isParentDefault = false,
}: INewsletterSignup) => {
  const [mailingListChecked, setMailingListChecked] = useState<Segment[]>([
    isParentDefault ? 'newsletter_parent' : 'newsletter_general',
  ]);
  const [showThanks, setShowThanks] = useState(false);

  const segments = useMemo(() => {
    return mailingListChecked.join(',');
  }, [mailingListChecked]);

  const disabled = useMemo(() => {
    return segments.length < 1 ? true : showThanks ? true : false;
  }, [segments, showThanks]);

  const btnColor = contrast ? 'bg-sandwisp' : 'bg-background';
  return (
    <div className={`${bgColor ? bgColor : 'bg-sandwisp'} pt-6 py-16 lg:py-16`}>
      <div className={`xl:flex ${thinMargins}`}>
        <div className="xl:w-2/3">
          <h3 className="text-h3mob lg:text-h3 font-semibold text-left">
            {title}
          </h3>
          {!!subtitle && (
            <p className="hidden xl:block text-smallbody font-semibold pt-6 font-inter">
              {subtitle}
            </p>
          )}
          <form
            action="https://europe-west2-luna-life-prod.cloudfunctions.net/joinMailingList"
            method="GET"
            target="stopredirect"
            id="newsletterSignUpForm"
            onSubmit={() => {
              segments.length < 1 ? console.log('ERROR') : setShowThanks(true);
            }}>
            <div className="flex flex-col xl:flex-row pt-8 gap-5 xl:gap-8 justify-between font-inter text-smallbody">
              <div className="xl:w-1/2 flex items-center">
                <div>
                  <input
                    className="h-5 w-5 checked:bg-primaryred bg-sandwisp rounded-[0.25rem]"
                    value="newsletter_general"
                    id="newsletter_general"
                    type="checkbox"
                    checked={mailingListChecked.includes('newsletter_general')}
                    onChange={e => {
                      if (e.target.checked) {
                        setMailingListChecked(
                          mailingListChecked.concat(e.target.value as Segment),
                        );
                      } else {
                        setMailingListChecked(
                          mailingListChecked.filter(
                            mailingList => mailingList !== e.target.value,
                          ),
                        );
                      }
                    }}
                  />
                </div>
                <label
                  htmlFor="newsletter_general"
                  className="pl-4 text-smallbody">
                  {generalCheckbox}
                </label>
              </div>
              <div className="xl:w-1/2 flex items-center">
                <input
                  className="h-5 w-5 checked:bg-primaryred bg-sandwisp rounded-[0.25rem]"
                  value="newsletter_parent"
                  id="newsletter_parent"
                  type="checkbox"
                  checked={mailingListChecked.includes('newsletter_parent')}
                  onChange={e => {
                    if (e.target.checked) {
                      setMailingListChecked(
                        mailingListChecked.concat(e.target.value as Segment),
                      );
                    } else {
                      setMailingListChecked(
                        mailingListChecked.filter(
                          mailingList => mailingList !== e.target.value,
                        ),
                      );
                    }
                  }}
                />
                <label
                  htmlFor="newsletter_parent"
                  className="pl-4 text-smallbody">
                  {parentCheckbox}
                </label>
              </div>
            </div>
            <div className="w-full">
              <iframe
                name="stopredirect"
                id="stopredirect"
                className="hidden"></iframe>
              <div className="mt-8">
                <input
                  type="text"
                  id="key"
                  name="key"
                  defaultValue="t425638l"
                  className="hidden"
                />
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  className="rounded-lg h-[3.5rem] p-6 font-inter border-none w-full"
                  required={true}
                />
                <input
                  type="text"
                  id="segments"
                  name="segments"
                  value={segments}
                  className="hidden"
                />
              </div>
              <button
                type="submit"
                className={`${disabled ? 'bg-[#EBD384]' : btnColor} hover:bg-[#EBD384] mt-6 xl:mt-8 text-black font-semibold text-smallbody hover:text-hoverblack w-fit py-2 px-6 rounded-3xl `}
                id="submit"
                disabled={disabled}>
                {showThanks ? 'Thank you!' : 'Submit'}
              </button>
            </div>
          </form>
          <div className="text-caption font-inter pt-6 xl:pt-10">
            <p>{disclaimer}</p>
          </div>
        </div>
        <div className="hidden xl:block xl:w-1/3 xl:ml-36">
          <img
            src={headerImage}
            className="hidden xl:block xl:ml-auto xl:max-w-[23rem] xl:rounded-[2rem] xl:object-cover"
            alt="two girls crossing their arms and leaning on eachother, smiling"
          />
        </div>
      </div>
    </div>
  );
};

export const ParentNewsletterSignupCard = ({
  title,
  subtitle,
  disclaimer,
}: INewsletterSignup) => {
  const mailingListChecked = 'newsletter_parent';
  const [showThanks, setShowThanks] = useState(false);

  const segments = mailingListChecked;

  const disabled = useMemo(() => {
    return segments.length < 1 ? true : showThanks ? true : false;
  }, [segments, showThanks]);
  return (
    <div
      className={`bg-sandwisp rounded-[2rem] mt-8  lg:max-w-none mx-autopt-6 p-10`}>
      <div className="">
        <h3 className="text-center text-h3mob lg:text-h3 font-semibold text-left text-primaryred">
          {title}
        </h3>
        {!!subtitle && (
          <p className="text-center text-black text-smallbody font-semibold pt-6 font-inter">
            {subtitle}
          </p>
        )}
        <form
          action="https://europe-west2-luna-life-prod.cloudfunctions.net/joinMailingList"
          method="GET"
          target="stopredirect"
          id="newsletterSignUpForm"
          onSubmit={() => {
            segments.length < 1 ? console.log('ERROR') : setShowThanks(true);
            Tracker.trackLead({leadSource: 'parent_newsletter'});
          }}>
          <div className="w-full">
            <iframe
              name="stopredirect"
              id="stopredirect"
              className="hidden"></iframe>
            <div className="mt-6">
              <input
                type="text"
                id="key"
                name="key"
                defaultValue="t425638l"
                className="hidden"
              />
              <input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                className="rounded-lg h-[3.5rem] p-6 font-inter border-none w-full"
                required={true}
              />
              <input
                type="text"
                id="segments"
                name="segments"
                value={segments}
                className="hidden"
              />
            </div>
            <button
              type="submit"
              className={`${disabled ? 'bg-[#EBD384]' : 'bg-background'} hover:bg-[#EBD384] mt-5 text-black font-semibold text-smallbody hover:text-hoverblack w-fit py-2 px-6 rounded-3xl `}
              id="submit"
              disabled={disabled}>
              {showThanks ? 'Thank you!' : 'Sign up'}
            </button>
          </div>
        </form>
        <div className="text-caption font-inter pt-6 xl:pt-8">
          <p>{disclaimer}</p>
        </div>
      </div>
    </div>
  );
};
