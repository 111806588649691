import React from 'react';

import {Button, Link} from '../../components';

export const CookieBanner = ({
  showCookieConsent,
  onAcceptAllCookies: onAcceptAllCookiesProp,
  onManageSettings: onManageSettingsProp,
}: {
  showCookieConsent: boolean;
  onAcceptAllCookies: () => void;
  onManageSettings: () => void;
}) => {
  const [styling, setStyling] = React.useState('');
  const [mounted, setMounted] = React.useState(showCookieConsent);

  const onAccept = () => {
    onAcceptAllCookiesProp();
    setStyling('animate-fadeOut');
  };

  const onManageSettings = () => {
    onManageSettingsProp();
  };

  React.useEffect(() => {
    let timer: number;
    if (!showCookieConsent) {
      timer = window.setTimeout(() => {
        setMounted(false);
      }, 500);
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [showCookieConsent]);

  if (!mounted) {
    return null;
  }

  return (
    <div className={`fixed bottom-0 bg-background w-full z-40 ${styling}`}>
      <div className="sm:flex justify-between md:justify-center items-center gap-10 md:gap-14 mx-4 mt-3 sm:mt-auto sm:mx-8">
        <p className="text-smallbodymob md:text-smallbody my-4">
          🍪 We use cookies and similar technologies to enhance your experience,
          improve our product and measure the effectiveness of ads. For more
          information, please read our{' '}
          <Link linkTo="privacy-policy" className="text-hotcalypso">
            Privacy Policy.
          </Link>
        </p>
        <div className="flex-col md:flex-row w-full md:justify-center md:min-w-fit px-3 md:px-0 py-3 flex gap-4">
          <Button label="Accept all" onClick={onAccept} fullWidth />
          <Button
            label="Manage preferences"
            onClick={onManageSettings}
            type="secondary"
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};
