import React, {useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {FlipCard, Icon} from '../../components/common';
import {colors} from '../../theme';
import {useScreenSize} from '../../utils/useScreenSize';
import {Person} from '../../types';
import {useSetCarouselInterval} from '../../utils';
interface IMedicCarousel {
  medics: Person[];
  title: string;
  subtitle: string;
}

export const MedicCarousel = ({medics, title, subtitle}: IMedicCarousel) => {
  const screenSize = useScreenSize();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const previousSlide = () => {
    setIsSlideshowPaused(true);
    if (currentSlideIndex === 0) setCurrentSlideIndex(medics.length - 1);
    else setCurrentSlideIndex(currentSlideIndex - 1);
  };

  const nextSlide = () => {
    setIsSlideshowPaused(true);
    if (currentSlideIndex === medics.length - 1) setCurrentSlideIndex(0);
    else setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const isLastSlide = () => {
    if (currentSlideIndex === medics.length) return true;
    else return false;
  };

  const [isSlideshowPaused, setIsSlideshowPaused] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
    trackMouse: true,
    preventScrollOnSwipe: true,
    onTap: () => {
      setIsSlideshowPaused(true);
    },
  });

  useSetCarouselInterval({
    isSlideshowPaused,
    currentSlideIndex,
    lastSlideIndex: medics.length,
    onSetCurrentSlideIndex: setCurrentSlideIndex,
    time: 2900,
  });

  const cardSize = useMemo(() => {
    if (
      screenSize === 'large' ||
      screenSize === 'extra-large' ||
      screenSize === 'xx-large'
    ) {
      setIsSlideshowPaused(true);
      return 30;
    }
    setIsSlideshowPaused(false);
    return 21;
  }, [screenSize]);

  return (
    <div
      className="md:flex ml-0 md:ml-10 lg:ml-28 xl:ml-40 2xl:ml-[10%] items-center"
      {...handlers}>
      <div className="md:min-w-[30rem] pr-8 ml-8 ">
        <h3 className="text-h3mob md:text-h2 font-title text-black">{title}</h3>
        <p className="text-largebodymob lg:text-largebody text-black pt-5 md:pt-10 pb-10">
          {subtitle}
        </p>
        <div className="hidden md:flex gap-6 ">
          <button onClick={previousSlide}>
            <Icon
              iconName={'left'}
              fill={colors.white}
              height={75}
              width={75}
            />
          </button>
          <button onClick={nextSlide}>
            <Icon
              iconName={'right'}
              fill={colors.white}
              height={75}
              width={75}
            />
          </button>
        </div>
      </div>
      <div className="overflow-hidden">
        <div
          className={`flex ml-6 gap-4 lg:gap-8 transition ease-out duration-500 h-[25rem] lg:h-[36rem] `}
          style={{
            transform: `${
              isLastSlide()
                ? ''
                : `translatex(-${currentSlideIndex * cardSize}rem)`
            }`,
          }}>
          {medics.map((medic, key) => {
            return (
              <div className="min-w-[20rem] lg:min-w-[28rem]" key={key}>
                <FlipCard
                  key={key}
                  image={medic.image}
                  name={medic.name}
                  description={medic.description}
                  pronouns={medic.pronouns}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
