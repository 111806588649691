import React from 'react';
import {thinMargins} from '../../constants';
import {TextImageBlock} from '../../types';
import {GatsbyImage} from 'gatsby-plugin-image';
import {MobileCarousel} from '../../components/common/MobileCarousel';

const AboutUsText = ({title, text}: {title: string; text: string}) => {
  return (
    <div className="text-black">
      <h2 className="text-subtitlelargemob md:text-subtitlelarge font-bold">
        {title}
      </h2>
      <p className="text-largebodymob md:text-smallbody pt-2 md:pt-6">{text}</p>
    </div>
  );
};

interface IAboutUsHeader {
  aboutUsBlocks: TextImageBlock[];
  title: string;
}

export const AboutUsHeader = ({aboutUsBlocks, title}: IAboutUsHeader) => {
  return (
    <div className={`${thinMargins} text-black md:pb-16`}>
      <h1 className="text-h3mob md:text-h2 font-title md:text-center lg:text-start">
        {title}
      </h1>
      <div className="hidden md:flex pt-20 gap-24">
        <div className="w-1/2 flex flex-col justify-between">
          <AboutUsText
            title={aboutUsBlocks[0].title}
            text={aboutUsBlocks[0].description}
          />

          {aboutUsBlocks[1].image && (
            <GatsbyImage
              alt={aboutUsBlocks[1].image?.alt || ''}
              image={aboutUsBlocks[1].image?.gatsbyImageData}
              className="mt-10 min-h-[27rem] w-full h-fill mx-auto object-cover rounded-[2rem]"
              draggable={false}
            />
          )}
        </div>
        <div className="w-1/2 flex flex-col justify-between">
          {aboutUsBlocks[0].image && (
            <GatsbyImage
              alt={aboutUsBlocks[0].image?.alt || ''}
              image={aboutUsBlocks[0].image?.gatsbyImageData}
              className="mb-10 min-h-[27rem] w-full mx-auto object-cover rounded-[2rem]"
              draggable={false}
            />
          )}
          <AboutUsText
            title={aboutUsBlocks[1].title}
            text={aboutUsBlocks[1].description}
          />
        </div>
      </div>
      <div className="hidden md:flex pt-10 gap-24">
        <div className="w-1/2 flex flex-col justify-between">
          <AboutUsText
            title={aboutUsBlocks[2].title}
            text={aboutUsBlocks[2].description}
          />

          {aboutUsBlocks[3].image && (
            <GatsbyImage
              alt={aboutUsBlocks[3].image?.alt || ''}
              image={aboutUsBlocks[3].image?.gatsbyImageData}
              className="mt-10 min-h-[24rem] max-h-[30rem] w-full mx-auto object-cover rounded-[2rem]"
              draggable={false}
            />
          )}
        </div>
        <div className="w-1/2 flex flex-col justify-between">
          {aboutUsBlocks[2].image && (
            <GatsbyImage
              alt={aboutUsBlocks[2].image?.alt || ''}
              image={aboutUsBlocks[2].image?.gatsbyImageData}
              className="mb-10 min-h-[27rem] w-full mx-auto object-cover rounded-[2rem]"
              draggable={false}
            />
          )}
          <AboutUsText
            title={aboutUsBlocks[3].title}
            text={aboutUsBlocks[3].description}
          />
        </div>
      </div>
      <div className="py-8">
        <MobileCarousel slides={aboutUsBlocks}>
          {aboutUsBlocks.map((b, i) => (
            <div className="min-w-full" key={i}>
              {b.image && (
                <GatsbyImage
                  alt={b.image?.alt || ''}
                  image={b.image?.gatsbyImageData}
                  className="pb-4 min-h-[22rem] max-h-[30rem] mx-auto object-cover rounded-[2rem]"
                  draggable={false}
                  imgStyle={{objectPosition: 'center'}}
                />
              )}
              <div className="pt-4">
                <AboutUsText title={b.title} text={b.description} />
              </div>
            </div>
          ))}
        </MobileCarousel>
      </div>
    </div>
  );
};
