import React, {useEffect, useMemo, useState} from 'react';
import {useRef} from 'react';
import {Icon} from './Icon';

interface IModal {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  isDark?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Modal = ({
  isOpen,
  hasCloseBtn,
  isDark,
  onClose,
  children,
}: IModal) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const clickOutsideToClose = e => {
    if (e.target.id === 'modal') handleCloseModal();
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
        modalElement.addEventListener('click', clickOutsideToClose);
      } else {
        modalElement.close();
        modalElement.removeEventListener('click', clickOutsideToClose);
      }
    }
  }, [isModalOpen]);

  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  const style = useMemo(() => {
    if (isDark) {
      return 'bg-background backdrop:bg-black backdrop:opacity-80 py-8 px-14 rounded-[32px] text-black lg:w-2/3 2xl:max-w-screen-xl';
    } else {
      return 'bg-background backdrop:bg-bluerice backdrop:opacity-80 py-8 px-14 rounded-[32px] text-black lg:w-2/3 2xl:max-w-screen-xl';
    }
  }, [isDark]);

  return (
    <dialog
      ref={modalRef}
      onKeyDown={handleKeyDown}
      className={style}
      id="modal">
      <div>
        {hasCloseBtn && (
          <div className="static pb-6">
            <button
              className="modal-close-btn absolute right-0 top-0 m-4"
              onClick={handleCloseModal}>
              <Icon iconName={'close'} fill="#DEDEE2" height={34} width={34} />
            </button>
          </div>
        )}
        {children}
      </div>
    </dialog>
  );
};
