import React from 'react';

import {PremiumFeature} from '../../types';
import {Icon} from '../../components';
import {colors} from '../../theme';

export const PremiumFeatures = ({features}: {features: PremiumFeature[]}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center my-2">
        <div className="flex flex-1">
          <p className="text-largebody font-semibold">What's included</p>
        </div>

        <div className="w-16 flex flex-col items-center text-center">
          <span className="text-smallbody font-semibold">Premium</span>
        </div>

        <div className="w-16 flex flex-col items-center text-center">
          <span className="text-smallbody font-semibold">Free</span>
        </div>
      </div>

      {features.map(f => {
        return (
          <div key={f.title} className="flex flex-row items-center my-2">
            <div className="flex flex-1">
              <p className="text-largebody">{f.title}</p>
            </div>

            <div className="w-16 flex flex-col items-center text-center">
              {f.includedInPremiumTier && (
                <>
                  {f.premiumTierSpecifics ? (
                    <span className="text-caption">
                      {f.premiumTierSpecifics}
                    </span>
                  ) : (
                    <Icon
                      iconName="tick-circle"
                      color={colors.black}
                      fill={colors.bluerice}
                      width={24}
                      height={24}
                    />
                  )}
                </>
              )}
            </div>

            <div className="w-16 flex flex-col items-center text-center">
              {f.includedInFreeTier && (
                <>
                  {f.freeTierSpecifics ? (
                    <span className="text-caption">{f.freeTierSpecifics}</span>
                  ) : (
                    <Icon
                      iconName="tick-circle"
                      color={colors.black}
                      fill={'#fff'}
                      width={24}
                      height={24}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
