import React, {useState, useMemo, useCallback} from 'react';
import {useSwipeable} from 'react-swipeable';
import {StarRating} from '../StarRating';
import {thinMargins} from '../../constants';
import reviewBackground from '../../images/parents/parent-reviews.png';
import {ParentReview} from '../../types';
import {StyledStructuredText} from '../../components/common/StyledStructuredText';
import {usePageWidth} from '../../utils';

const ParentReviewCard = ({title, reviewerName, review}: ParentReview) => {
  return (
    <div className="min-w-[100%] px-[5%]">
      <div className="bg-white rounded-[1.5rem] py-8 px-4 md:px-6 text-black h-full flex flex-col justify-between gap-6 lg:mx-auto">
        <div>
          <h3 className="text-largebodymob lg:text-subtitlesmall font-title">
            {title}
          </h3>
          <StyledStructuredText data={review.value} />
        </div>
        <p className="text-smallbodymob lg:text-smallbody font-semibold">
          {reviewerName}&nbsp;
        </p>
      </div>
    </div>
  );
};

interface IParentReviews {
  reviews: ParentReview[];
  appRating: number;
  title: string;
}

export const ParentReviews = ({reviews, appRating, title}: IParentReviews) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const lastSlideIndex = useMemo(() => reviews.length, [reviews]);
  const pageWidth = usePageWidth();

  const onGoToPreviousSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return lastSlideIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === lastSlideIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === lastSlideIndex;
  }, [currentSlideIndex, lastSlideIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  return (
    <div
      style={{
        backgroundImage: `url(${reviewBackground})`,
      }}
      className=" py-28 max-w-full bg-cover bg-center md:bg-right-top">
      <div
        className={`${thinMargins} flex flex-col justify-center items-center gap-6`}>
        <h2 className="text-h3mob lg:text-h3 font-title text-white text-center">
          {title}
        </h2>
        <StarRating rating={appRating} starColor={'yellow'} mode="light" />
        {/* Desktop */}
        <div className="hidden lg:flex gap-5 min-h-[22.6rem] max-h-fit ">
          {reviews.map((r, i) => (
            <div className="w-1/3" key={i}>
              <ParentReviewCard
                title={r.title}
                reviewerName={r.reviewerName}
                key={i}
                review={r.review}
              />
            </div>
          ))}
        </div>

        {/* Mobile carousel */}
        <div className="lg:hidden" {...handlers}>
          <div className="overflow-hidden ">
            <div
              className={`flex transition ease-out duration-200`}
              style={{
                width: pageWidth,
                transform: `${
                  isLastSlide ? '' : `translatex(-${currentSlideIndex * 100}%)`
                }`,
              }}>
              {reviews.map((r, i) => (
                <ParentReviewCard
                  title={r.title}
                  reviewerName={r.reviewerName}
                  key={i}
                  review={r.review}
                />
              ))}
            </div>
            <div className="py-5 flex justify-center gap-2 w-full">
              {reviews.map((r, i) => (
                <button
                  onClick={() => {
                    setCurrentSlideIndex(i);
                  }}
                  className={`w-10 h-1 bg-white ${
                    i === currentSlideIndex ? 'bg-opacity-100' : 'bg-opacity-40'
                  } `}
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
