import * as React from 'react';
import {FlipCard} from '../../components';
import {thinMargins, wideMargins} from '../../constants';
import {Person} from '../../types';

interface ICoFounders {
  cofounders: Person[];
  title: string;
}

export const CoFounders = ({cofounders, title}: ICoFounders) => {
  return (
    <div className={`${thinMargins}`}>
      <h2
        className={`text-h3mob lg:text-h3 font-title text-black text-center pb-8 lg:pb-10 ${wideMargins}`}>
        {title}
      </h2>
      <div
        className={`flex flex-col justify-center gap-8 lg:flex-row lg:gap-6 items-center lg:max-w-none mx-auto lg:mx-28 xl:mx-40 `}>
        {cofounders.map((cofounder, i) => (
          <div
            key={i}
            className="h-[27rem] lg:h-[25rem] min-w-[20rem] md:min-w-[28rem] w-[20rem] md:w-[28rem]">
            <FlipCard
              image={cofounder.image}
              name={cofounder.name}
              description={cofounder.description}
              pronouns={cofounder.pronouns}
              key={i}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
