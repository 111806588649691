import {AnalyticsTracker} from './AnalyticsTracker';
import {isBrowser} from '../../utils';

declare global {
  interface Window {
    gtag: (a: string, b: string, params?: any) => void;
  }
}

function shouldTrack() {
  return (
    process.env.NODE_ENV === 'production' &&
    typeof window === 'object' &&
    typeof window.gtag === 'function'
  );
}

export const GoogleTracker: AnalyticsTracker = {
  trackInitiateCheckout({productId}) {
    if (shouldTrack()) {
      window.gtag('event', 'begin_checkout', {items: [{id: productId}]});
    }
  },

  trackLead({leadSource}) {
    if (shouldTrack()) {
      window.gtag('event', 'generate_lead', {
        currency: 'gbp',
        value: 0,
        lead_source: leadSource,
      });
    }
  },

  trackPurchase({sessionId, productId, productName, value, currency}) {
    if (shouldTrack()) {
      window.gtag('event', 'purchase', {
        value,
        currency,
        transaction_id: sessionId,
        items: [{id: productId, name: productName}],
      });
    }
  },

  trackSubscribe({sessionId, productId, productName, value, currency}) {
    if (shouldTrack()) {
      window.gtag('event', 'purchase', {
        value,
        currency,
        transaction_id: sessionId,
        items: [{id: productId, name: productName}],
      });
    }
  },

  trackContentCardClick({articleId}) {
    if (shouldTrack()) {
      window.gtag('event', 'content_card_click', {
        articleId: articleId,
      });
    }
  },
};

// Opt-out function - sets window['ga-disable-MEASUREMENT_ID'] to true
// based on: https://developers.google.com/tag-platform/security/guides/privacy#turn-off-analytics
export const gaOptOut = () => {
  if (isBrowser) {
    const gaProperty = process.env.GATSBY_GA_MEASUREMENT_ID;
    const disableStr = 'ga-disable-' + gaProperty;
    // @ts-expect-error (we need to set something on global window here soz)
    window[disableStr] = true;
  }
};
