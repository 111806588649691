import * as React from 'react';
import {Button, TickerList} from '../../components';
import work from '../../images/work/schools.png';
import {wideMargins} from '../../constants';
import {
  StructuredText,
  StructuredTextDocument,
} from 'react-datocms/structured-text';

interface ISchools {
  schoolLogoList: {name: string; img: string}[];
  title: string;
  content: StructuredTextDocument;
}

export const Schools = ({schoolLogoList, title, content}: ISchools) => {
  return (
    <div
      className={`flex flex-col lg:flex-row gap-8 lg:gap-12 xl:gap-20 items-center md:mx-32 ${wideMargins} py-20`}>
      <div className="lg:w-2/5">
        <img
          src={work}
          alt="the luna team gathered around a table with school children"
          className="rounded-[2rem] max-h-[28rem] md:max-h-none "
        />
      </div>

      <div className="text-black text-center lg:text-start flex flex-col w-auto mx-auto lg:w-3/5">
        <h2 className="text-h3mob lg:text-h3 font-title ">{title}</h2>
        <div className="text-largebodymob lg:text-largebody pt-6">
          <StructuredText data={content} />
        </div>

        <div className="flex justify-center lg:justify-start gap-6 items-center pt-8 lg:pt-10 pb-8 lg:pb-12">
          <div className="min-w-fit h-[3.5rem]">
            <Button label="Find out more" type="primary" linkTo="schools" />
          </div>
        </div>
        <div className="hidden lg:flex">
          <TickerList itemList={schoolLogoList} />
        </div>
      </div>
      {/* had to move this out for mob otherwise it was stretching the margins of the text above */}
      <div className="w-full lg:hidden">
        <TickerList itemList={schoolLogoList} />
      </div>
    </div>
  );
};
