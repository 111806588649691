import * as React from 'react';
import {wideMargins} from '../../constants';
import {Button} from '../../components';
import press from '../../images/work/jandj.jpg';
import {
  StructuredText,
  StructuredTextDocument,
} from 'react-datocms/structured-text';
import {PrLogos} from './PrLogos';

export type pressLogoList = {name: string; img: string}[];

interface IPress {
  pressLogoList: pressLogoList[];
  title: string;
  subtitle: string;
  content: StructuredTextDocument;
}

export const Press = ({pressLogoList, title, subtitle, content}: IPress) => {
  return (
    <div className={`md:mx-32 ${wideMargins} py-14`}>
      <div className="flex flex-col-reverse lg:flex-row gap-8 lg:gap-12 xl:gap-20 items-center pb-16">
        <div className="text-black text-center lg:text-start flex flex-col lg:w-3/5">
          <h2 className="text-h3mob lg:text-h3 font-title ">{title}</h2>
          <p className="text-largebodymob lg:text-largebody pt-6">{subtitle}</p>
          <StructuredText data={content} />
          <div className="flex justify-center lg:justify-start gap-6 items-center pt-8 lg:pt-10 pb-8 lg:pb-12">
            <div className="min-w-fit h-[3.5rem]">
              <Button
                label="Contact us"
                type="primary"
                externalLinkUrl="mailto:hello@weareluna.app"
              />
            </div>
            <div className="min-w-fit h-[3.5rem]">
              <Button
                label="LinkedIn"
                type="outline"
                externalLinkUrl="https://www.linkedin.com/company/lunalifeltd/"
              />
            </div>
          </div>
        </div>
        <img
          src={press}
          alt="co-founders Jas and Jo at the Apple International Women's Day photoshoot"
          className="rounded-[2rem] min-h-[20rem] max-h-[25rem] lg:max-h-none lg:w-2/5"
        />
      </div>
      <PrLogos pressLogoList={pressLogoList} tickerOnly />
    </div>
  );
};
