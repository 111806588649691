import React, {useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import blueTab from '../../images/homepage/blue-tab.png';
import {Feature} from '../../types';
import {GatsbyImage} from 'gatsby-plugin-image';
import {thinMargins} from '../../constants';
import {useSetCarouselInterval} from '../../utils';

interface IFeatureDescription {
  title: string;
  description: string;
  activeTab: Feature;
  onClick: () => void;
}

const FeatureDescription = ({
  title,
  description,
  activeTab,
  onClick,
}: IFeatureDescription) => {
  const isActive = activeTab.title === title;
  const activeStyle = 'opacity-100';
  const inactiveStyle = 'opacity-50';
  return (
    <div className="flex pt-3">
      <div className="flex gap-7 items-center">
        <img
          src={blueTab}
          style={{
            visibility: isActive ? 'visible' : 'hidden',
            height: 'max-content',
          }}
        />
        <div
          className={`cursor-pointer ${isActive ? activeStyle : inactiveStyle}`}
          onClick={onClick}>
          <h3 className="text-black text-subtitlelarge font-bold">{title}</h3>
          <p className="text-black text-largebody">{description}</p>
        </div>
      </div>
    </div>
  );
};

interface IMobileFeature {
  feature: Feature;
  context: 'parent' | 'teen' | 'home';
}

const MobileFeature = ({feature, context}: IMobileFeature) => {
  const isHome = context === 'home';
  return (
    <div className="text-black mx-[5%] min-w-[90%] pb-4">
      <div
        className={` ${
          isHome ? 'h-[27rem] bg-[#FFECB0]' : 'max-h-[20rem]'
        } rounded-3xl overflow-hidden`}>
        <GatsbyImage
          alt={feature.image.alt}
          image={feature.image.gatsbyImageData}
          className={`mx-auto ${
            isHome
              ? 'mt-7 max-w-[18rem]'
              : 'max-w-[20rem] md:max-w-[22rem] rounded-[2rem] object-contain'
          }`}
          draggable={false}
        />
      </div>
      <div className={` ${isHome ? 'pt-12 text-start' : 'pt-8 text-center'}`}>
        <h3 className="text-subtitlelargemob font-bold ">{feature.title}</h3>
        <p className="text-largebodymob pt-2">{feature.description}</p>
      </div>
    </div>
  );
};

export const MobileFeatureSelector = ({
  features,
  context,
}: {
  features: Feature[];
  context: 'parent' | 'teen' | 'home';
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const previousSlide = () => {
    setIsSlideshowPaused(true);
    if (currentSlideIndex === 0) setCurrentSlideIndex(features?.length - 1);
    else setCurrentSlideIndex(currentSlideIndex - 1);
  };

  const nextSlide = () => {
    setIsSlideshowPaused(true);
    if (currentSlideIndex === features.length - 1) setCurrentSlideIndex(0);
    else setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const isLastSlide = () => {
    if (currentSlideIndex === features.length) return true;
    else return false;
  };

  const [isSlideshowPaused, setIsSlideshowPaused] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
    trackMouse: true,
    preventScrollOnSwipe: true,
    onTap: () => {
      setIsSlideshowPaused(true);
    },
  });

  useSetCarouselInterval({
    isSlideshowPaused,
    currentSlideIndex,
    lastSlideIndex: features.length,
    onSetCurrentSlideIndex: setCurrentSlideIndex,
    time: 3000,
  });

  return (
    <div className="lg:hidden relative" {...handlers}>
      <div className="overflow-hidden ">
        <div
          className={`flex transition ease-out duration-200`}
          style={{
            transform: `${
              isLastSlide() ? '' : `translatex(-${currentSlideIndex * 100}%)`
            }`,
          }}>
          {features.map((feature, i) => (
            <MobileFeature key={i} feature={feature} context={context} />
          ))}
        </div>
        <div
          className={`absolute  ${
            context === 'home' ? 'py-4 top-[27.5rem]' : 'pb-2 top-[21rem]'
          } gap-2 flex justify-center w-full`}>
          {features.map((f, i) => (
            <div
              onClick={() => {
                setCurrentSlideIndex(i);
              }}
              className={`w-10 h-1 bg-white ${
                i === currentSlideIndex ? 'bg-opacity-100' : 'bg-opacity-40'
              } `}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const FeatureSelector = ({features}: {features: Feature[]}) => {
  const [activeTab, setActiveTab] = useState<Feature>(features[0]);

  return (
    <>
      <div
        className={`hidden lg:flex justify-center gap-32 pb-20 xl:mx-40 2xl:mx-72 ${thinMargins}`}>
        <div className="w-1/2">
          {features.map((feature, i) => (
            <FeatureDescription
              key={i}
              title={feature.title}
              description={feature.description}
              onClick={() => {
                setActiveTab(feature);
              }}
              activeTab={activeTab}
            />
          ))}
        </div>
        <div className="w-1/3">
          <GatsbyImage
            alt={activeTab?.image.alt || ''}
            image={activeTab.image.gatsbyImageData}
            className="max-w-[25rem] max-h-[51rem]"
            draggable={false}
          />
        </div>
      </div>
      <MobileFeatureSelector features={features} context="home" />
    </>
  );
};
