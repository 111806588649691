import * as React from 'react';
import {thinMargins} from '../../constants';
import {ExplainerFacts, IExplainerFact} from './ExplainerFact';
import {Button} from '../../components';

interface IParentExplainer {
  facts: readonly (IExplainerFact | null)[] | null;
  title: string;
  subtitle: string;
  hasBottomPadding?: boolean;
  showContactUs?: boolean;
}

export const ParentExplainer = ({
  facts,
  title,
  subtitle,
  hasBottomPadding = true,
  showContactUs = false,
}: IParentExplainer) => {
  const bottomPadding = hasBottomPadding ? 'pb-20 lg:pb-28' : 'pb-8';
  return (
    <div className={`text-black ${bottomPadding} ${thinMargins}`}>
      <h2 className="text-h3mob md:text-h3 font-title pb-6 lg:text-center">
        {title}
      </h2>
      <p className="text-largebodymob md:text-largebody pb-8 md:pb-14 lg:text-center">
        {subtitle}
      </p>
      {showContactUs && (
        <div className="flex justify-center md:-mt-6 pb-8">
          <Button
            label="Contact us"
            type="primary"
            externalLinkUrl="mailto:hello@weareluna.app"
          />
        </div>
      )}

      <div className="flex justify-between">
        <ExplainerFacts facts={facts} />
      </div>
    </div>
  );
};
