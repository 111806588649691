import * as React from 'react';
import {pressLogoList} from './Press';
import {TickerList} from '../../components';

export const PrLogos = ({
  pressLogoList,
  tickerOnly = false,
}: {
  pressLogoList: pressLogoList[];
  tickerOnly?: boolean;
}) => {
  if (tickerOnly) {
    return (
      <div className="flex ">
        <TickerList itemList={pressLogoList.flat()} />
      </div>
    );
  } else
    return (
      <>
        {pressLogoList.map((list, i) => (
          <ul className={`hidden lg:flex justify-between pt-4`} key={i}>
            {list.map((p, i) => {
              return (
                <>
                  <li className="mx-3 pt-2" key={i}>
                    <img src={p.img} key={i} alt={`an logo of ${p.name}`} />
                  </li>
                </>
              );
            })}
          </ul>
        ))}

        <div className="flex lg:hidden">
          <TickerList itemList={pressLogoList.flat()} />
        </div>
      </>
    );
};
