import {AnalyticsTracker} from './AnalyticsTracker';

import {MetaTracker} from './meta';
import {GoogleTracker} from './google';

const trackers: AnalyticsTracker[] = [MetaTracker, GoogleTracker];

export const Tracker: AnalyticsTracker = {
  trackInitiateCheckout: args => {
    trackers.forEach(tracker => {
      tracker.trackInitiateCheckout(args);
    });
  },

  trackPurchase: args => {
    trackers.forEach(tracker => {
      tracker.trackPurchase(args);
    });
  },

  trackSubscribe: args => {
    trackers.forEach(tracker => {
      tracker.trackSubscribe(args);
    });
  },

  trackLead: args => {
    trackers.forEach(tracker => {
      tracker.trackLead(args);
    });
  },

  trackTapAppDownloadButton: () => {
    trackers.forEach(tracker => {
      if (tracker.trackTapAppDownloadButton) {
        tracker.trackTapAppDownloadButton();
      }
    });
  },

  trackContentCardClick: args => {
    trackers.forEach(tracker => {
      if (tracker.trackContentCardClick) {
        tracker.trackContentCardClick(args);
      }
    });
  },
};
