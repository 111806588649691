import * as React from 'react';
import {ImageCard} from '../../components/common';
import parentCard from '../../images/teen-card.png';
import teenCard from '../../images/parent-card.png';
import workCard from '../../images/work-card.png';
import {thinMargins} from '../../constants';

export const FindOutMore = () => {
  const CardText = ({text}: {text: string}) => (
    <p className="text-white text-subtitlesmall font-title text-center font-poppins px-6">
      {text}
    </p>
  );

  return (
    <div className={`gap-5 pb-8 lg:pb-16 pt-8 ${thinMargins}`}>
      <h3 className="text-subtitlelargemob lg:text-subtitlelarge text-black font-title text-center pb-10">
        Find out more
      </h3>
      <div className="flex gap-5 flex-col md:flex-row">
        <ImageCard imgSrc={teenCard} linkTo={'teens'} height="h-64 md:h-52">
          <CardText text={"I'm a teen"} />
        </ImageCard>
        <ImageCard imgSrc={parentCard} linkTo={'parents'} height="h-64 md:h-52">
          <CardText text={"I'm a parent/guardian"} />
        </ImageCard>
        <ImageCard
          imgSrc={workCard}
          linkTo={'work-with-us'}
          height="h-64 md:h-52">
          <CardText text={'Work with us'} />
        </ImageCard>
      </div>
    </div>
  );
};
