import * as React from 'react';
import {
  StructuredText,
  StructuredTextDocument,
  renderNodeRule,
} from 'react-datocms';
import {isList, isParagraph, isLink} from 'datocms-structured-text-utils';
import {ContentCard} from '../../features';

interface IStyledStructuredText {
  data: StructuredTextDocument;
  trackingId?: string;
}

export const StyledStructuredText = ({
  data,
  trackingId,
}: IStyledStructuredText) => {
  return (
    <StructuredText
      data={data}
      customNodeRules={[
        // this is needed as without, all list items seem to be rendering with a paragraph html tag inside
        renderNodeRule(isParagraph, ({children, key, ancestors}) => {
          if (
            ancestors[0].type === 'listItem' &&
            ancestors[0].children.length === 1
          ) {
            return <React.Fragment key={key}>{children}</React.Fragment>;
          }

          return (
            <p key={key} className="pt-3">
              {children}
            </p>
          );
        }),
        renderNodeRule(isList, ({children, key}) => {
          return (
            <ul key={key} className="list-disc list-outside pl-4">
              {children}
            </ul>
          );
        }),
        renderNodeRule(isLink, ({node, children, key}) => {
          return (
            <span key={key} className="text-grey underline">
              <a href={node.url}>{children}</a>
            </span>
          );
        }),
      ]}
      renderBlock={({record}) => {
        const recordArgs = record as any;

        if (recordArgs.image && recordArgs.image.url) {
          return (
            <img
              src={recordArgs.image.url}
              className="rounded-[2rem] mx-auto max-h-[23rem] w-full max-w-[22rem] py-3"
              alt={recordArgs.image.alt}
            />
          );
        }

        if (recordArgs.__typename === 'DatoCmsContentCardBlock') {
          return (
            <ContentCard
              text={recordArgs.content.cardText}
              color={recordArgs.content.color}
              buttonCta={recordArgs.content.buttonCta}
              buttonUrl={recordArgs.content.buttonUrl}
              image={recordArgs.content.image}
              trackingId={trackingId}
            />
          );
        }

        return null;
      }}
    />
  );
};
