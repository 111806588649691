import React from 'react';
import {Reference} from '../../types';

export const Sources = ({
  references,
  context,
}: {
  references: Reference[];
  context: 'article' | 'question';
}) => {
  return (
    <div className="pt-8">
      <p className="text-largebodymob lg:text-largebody font-title text-primaryred pb-2">
        {context === 'article'
          ? 'How we created this article:'
          : 'How we answered this question:'}
      </p>
      <p className="text-smallbodymob">
        luna&apos;s&nbsp;
        <a
          href="http://weareluna.app/about-us/#medics"
          className="text-grey underline">
          team of experts
        </a>
        &nbsp;comprises GPs, Dermatologists, Safeguarding Leads and Junior
        Doctors as well as Medical Students with specialised interests in
        paediatric care, mental health and gynaecology.{' '}
        {context === 'article'
          ? "All articles are created by experts, and reviewed by a member of luna's senior review team."
          : 'Questions are answered and reviewed by this team.'}
      </p>
      {!!references.length && (
        <div>
          <p className="font-semibold text-primaryred pt-5 pb-2">Sources:</p>
          {references.map((reference, i) => {
            return (
              <div key={i} className="pb-3">
                <p className="text-smallbodymob">{reference?.title} </p>
                <a
                  href={reference?.url || ''}
                  className="text-grey underline text-caption">
                  {reference?.url}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
