import * as React from 'react';
import Markdown from 'react-markdown';

import {StarRating} from '../StarRating';

interface IReviewCard {
  color?: string;
  title?: string;
  text: string;
  starCount: number;
  starColor?: 'black' | 'yellow';
  textAlign?: 'left' | 'center' | 'right';
  variant?: 'large' | 'regular';
  className?: string;
}

export const ReviewCard = ({
  title,
  text,
  starCount,
  starColor = 'black',
  textAlign = 'left',
  className,
  color,
  variant,
}: IReviewCard) => {
  let variantStyle = '';
  let reviewStyle = '';
  let v = variant;

  if (!v) {
    if (text.length > 221) {
      v = 'large';
    } else v = 'regular';
  }

  switch (v) {
    case 'large':
      variantStyle += 'h-[34rem] lg:h-[34rem]';
      reviewStyle = 'line-clamp-[18] lg:line-clamp-[19]';
      break;
    case 'regular':
      variantStyle += 'h-[16.5rem] lg:h-[16.5rem]';
      reviewStyle = 'line-clamp-[6]';
  }

  const textAlignFlexRow = `justify-${
    textAlign === 'left' ? 'start' : textAlign === 'right' ? 'end' : 'center'
  }`;

  return (
    <div
      className={`rounded-[1.5rem] text-black min-w-[19rem] lg:min-w-[22rem] p-6 ${color} ${variantStyle} text-${textAlign} ${className}`}>
      {!!title && (
        <p className="text-largebodymob lg:text-largebody font-title pb-2">
          {title}
        </p>
      )}
      <div className={`flex pb-2 gap-1 ${textAlignFlexRow}`}>
        <StarRating
          rating={starCount}
          starColor={starColor}
          showNumber={false}
        />
      </div>

      <p
        className={`text-smallbodymob lg:text-smallbody overflow-hidden ${reviewStyle}`}>
        <Markdown>{text}</Markdown>
      </p>
    </div>
  );
};
