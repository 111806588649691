import React, {useCallback, useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {Icon} from '../../components';
import {colors} from '../../theme';
import {thinMargins} from '../../constants';
import {useScreenSize, usePageWidth} from '../../utils';

export interface IStatCard {
  emoji: string;
  date?: string;
  stat: string;
  caption: string;
  variant?: 'about' | 'work';
}

export interface IStats {
  stats: IStatCard[];
  variant?: 'about' | 'work';
  title: string;
}

const StatCard = ({emoji, date, stat, caption, variant}: IStatCard) => {
  return (
    <div
      className={`rounded-[2rem] ${
        variant === 'work' ? 'bg-bluerice' : 'bg-sandwisp'
      } flex flex-col justify-between text-center items-center h-full px-5 lg:px-8 py-12 lg:py-16 w-[18rem] min-w-[18rem] lg:w-[26rem] lg:min-w-[26rem]`}>
      <div>
        <p className="text-h2mob lg:text-h2 pb-6">{emoji}</p>
        {date && (
          <p className="text-largebodymob lg:text-largebody font-semibold">
            {date}
          </p>
        )}
      </div>
      <h3 className="text-subtitlelargemob lg:text-subtitlelarge font-title">
        {stat}
      </h3>
      <p className="text-largebodymob lg:text-largebody">{caption}</p>
    </div>
  );
};

export const StatCardCarousel = ({stats, variant = 'about', title}: IStats) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const screenSize = useScreenSize();

  const pageWidth = usePageWidth();

  const setup = useMemo((): {
    transformSize: string;
    lastSlideIndex: number;
    marginLeft: number;
  } => {
    // setting different "lastSlideIndex" helps minimise extra white space at different breakpoints
    if (screenSize === 'xx-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 27.25}rem)`,
        lastSlideIndex: stats.length - 2,
        marginLeft: (pageWidth - 1280) / 2,
      };
    } else if (screenSize === 'extra-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 27.25}rem)`,
        lastSlideIndex: stats.length - 1,
        marginLeft: 160,
      };
    } else if (screenSize === 'large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 27.25}rem)`,
        lastSlideIndex: stats.length - 1,
        marginLeft: 128,
      };
    } else if (screenSize === 'medium') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 27.25}rem)`,
        lastSlideIndex: stats.length - 2,
        marginLeft: 32,
      };
    } else {
      return {
        transformSize: `translatex(-${currentSlideIndex * 19.25}rem)`,
        lastSlideIndex: stats.length,
        marginLeft: 32,
      };
    }
  }, [screenSize, currentSlideIndex, pageWidth]);

  const onGoToPreviousSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return value;
      } else {
        return value - 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === setup.lastSlideIndex - 1) {
        return value;
      } else {
        return value + 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === setup.lastSlideIndex;
  }, [currentSlideIndex, setup.lastSlideIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  return (
    <div className="relative text-black ">
      <div className={`max-w-screen-xl  ${thinMargins} pb-10`}>
        <h3 className={`text-h3mob lg:text-h3 font-title `}>{title}</h3>
      </div>
      <div {...handlers} style={{marginLeft: `${setup.marginLeft}px`}}>
        <div className="overflow-hidden">
          <div
            className={`flex transition ease-out duration-500 gap-5 h-[26rem] lg:h-[32rem]`}
            style={{
              transform: `${isLastSlide ? '' : `${setup.transformSize}`}`,
            }}>
            {stats.map((m, i) => (
              <StatCard
                emoji={m.emoji}
                date={m.date}
                stat={m.stat}
                caption={m.caption}
                key={i}
                variant={variant}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`absolute top-[55%] w-full hidden lg:flex lg:justify-between `}
        style={{
          paddingLeft: `${setup.marginLeft - 100}px`,
          paddingRight: `${setup.marginLeft - 100}px`,
        }}>
        <button
          onClick={onGoToPreviousSlide}
          disabled={currentSlideIndex === 0 ? true : false}
          className="disabled:opacity-70">
          <Icon iconName={'left'} fill={colors.white} height={75} width={75} />
        </button>
        <button
          onClick={onGoToNextSlide}
          disabled={
            currentSlideIndex === setup.lastSlideIndex - 1 ? true : false
          }
          className="disabled:opacity-70">
          <Icon iconName={'right'} fill={colors.white} height={75} width={75} />
        </button>
      </div>
    </div>
  );
};
