import * as React from 'react';
import aotd from '../images/app-ot-day-dark.png';
import qrCode from '../images/qr-code.png';
import {StarRating} from './StarRating';

interface IQRCard {
  appRating: number;
}

export const QRCard = ({appRating}: IQRCard) => {
  return (
    <div className="bg-white h-[9.6rem] min-w-[23rem] rounded-[1.5rem] flex p-3 gap-3 text-black">
      <div className="w-2/5">
        <img
          src={qrCode}
          className="h-full"
          alt="a qr code which takes you to download luna"
        />
      </div>
      <div className="w-3/5 pt-1">
        <p className="text-caption max-w-[9rem] pb-2 font-semibold">
          Scan the QR code to download the app
        </p>
        <StarRating rating={appRating} starColor="yellow" />
        <img
          src={aotd}
          className="mt-3 h-9"
          alt="apple's app of the day logo"
        />
      </div>
    </div>
  );
};
