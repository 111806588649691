import eustart from '../src/images/publications/eustart.png';
import grazia from '../src/images/publications/grazia.png';
import marie from '../src/images/publications/marie.png';
import ok from '../src/images/publications/ok.png';
import sheer from '../src/images/publications/sheerluxe.png';
import sifted from '../src/images/publications/sifted.png';
import glamour from '../src/images/publications/glamour.png';
import adidas from '../src/images/publications/adidas.png';
import businessInsider from '../src/images/publications/business-insider.png';
import dell from '../src/images/publications/dell.png';
import techcrunch from '../src/images/publications/techcrunch.png';

export const appStoreLink =
  'https://apps.apple.com/gb/app/we-are-luna/id1632059799';

export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.lunalife.luna';

export const redeemCodeDynamicLink = 'https://link.weareluna.app/CLSk';

const globalMargins = ' max-w-screen-xl lg:mx-32 2xl:mx-auto';

export const thinMargins = `${globalMargins} mx-8 md:mx-16 `;

export const wideMargins = `${globalMargins} mx-8 md:mx-32 `;

export const publicationsList1 = [
  {name: 'OK! Magazine', img: ok},
  {name: 'marie claire', img: marie},
  {name: 'Glamour', img: glamour},
  {name: 'Grazia', img: grazia},
  {name: 'TechCrunch', img: techcrunch},
  {name: 'Sheerluxe', img: sheer},
];

export const publicationsList2 = [
  {name: 'EU-Startups', img: eustart},
  {name: 'sifted', img: sifted},
  {name: 'adidas', img: adidas},
  {name: 'Business Insider', img: businessInsider},
  {name: "Dell Women's Entrepreneur Network", img: dell},
];
