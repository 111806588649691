type CookieOptions =
  | 'acceptAll'
  | 'declineAll'
  | 'essential'
  | 'analytics'
  | 'marketing'
  | 'functional';
export type CookieConsentStatus = CookieOptions[];

export function getCookieConsentStatusValue(): CookieConsentStatus | null {
  const value = localStorage.getItem('cookieStatusV2');

  if (value) {
    return JSON.parse(value);
  }
  return null;
}

export function setCookieConsentStatusValue(status: CookieConsentStatus) {
  localStorage.setItem('cookieStatusV2', JSON.stringify(status));
}

export type ParentPopUpStatus = 'seen';

export function getParentPopUpStatusValue(): ParentPopUpStatus | null {
  const value = localStorage.getItem('parentPopUpStatus');
  if (value === 'seen') {
    return value;
  }
  return null;
}

export function setParentPopUpStatusValue(status: ParentPopUpStatus) {
  localStorage.setItem('parentPopUpStatus', status);
}
