import React, {useState, useMemo, useCallback} from 'react';
import {useSwipeable} from 'react-swipeable';

import large from '../../images/homepage/review-img-lg.png';
import small from '../../images/homepage/review-img-sm.png';
import {Icon} from '../../components/common';
import {colors} from '../../theme';
import {useScreenSize} from '../../utils/useScreenSize';

import {StarRating} from '../StarRating';
import {ReviewCard} from './ReviewCard';
import {useSetCarouselInterval} from '../../utils';

const SMALL_REVIEW_MAX_CHAR_COUNT = 220;
const GRID_REFERENCE_OFFSET = 5;

const colorList = [
  'bg-sexualhealth',
  'bg-nutrition',
  'bg-periods',
  'bg-hotcalypso',
  'bg-bodyimage',
  'bg-relationships',
  'bg-hair',
  'bg-sleep',
  'bg-sex',
  'bg-gender',
  'bg-mentalhealth',
];

function getGridReference(text: string) {
  if (text.length > SMALL_REVIEW_MAX_CHAR_COUNT + GRID_REFERENCE_OFFSET) {
    return `row-span-2 col-span-1`;
  } else {
    return `row-span-1 col-span-1`;
  }
}

function getReviewColor(index: number) {
  const modulusIndex = index % colorList.length;
  return colorList[modulusIndex];
}

interface ICommunityReview {
  title?: string;
  review: string;
  starCount: number;
}
interface ICommunityReviews {
  reviews: ICommunityReview[];
  appRating: number;
  heroLargeReview: ICommunityReview[];
  heroSmallReview: ICommunityReview[];
}

export const CommunityReviews = ({
  reviews,
  appRating,
  heroLargeReview,
  heroSmallReview,
}: ICommunityReviews) => {
  const screenSize = useScreenSize();

  const smallCount = useMemo(() => {
    return reviews?.filter(review => {
      return review.review.length <= SMALL_REVIEW_MAX_CHAR_COUNT;
    });
  }, [reviews]);

  const colCount = useMemo(() => {
    if (reviews && smallCount) {
      return Math.round(reviews?.length - smallCount.length / 2);
    } else {
      return 1;
    }
  }, [reviews, smallCount]);

  const setup = useMemo((): {
    cardSize: number;
    startSlideIndex: number;
    lastCardIndex: number;
  } => {
    if (screenSize === 'xx-large') {
      return {
        cardSize: 23,
        startSlideIndex: 0,
        lastCardIndex: colCount,
      };
    } else if (
      screenSize === 'medium' ||
      screenSize === 'large' ||
      screenSize === 'extra-large'
    ) {
      return {
        cardSize: 23,
        startSlideIndex: 0,
        // colCount + 1 accommodates enough space for static 3 columns up front
        // but removes some trailing white space
        lastCardIndex: colCount + 1,
      };
    } else {
      // mobile
      return {
        cardSize: 20,
        // startSlideIndex 1 so that on mobile, it opens on the large hero card
        startSlideIndex: 1,
        // colCount + 3 so that it takes into account the static 3 columns up front
        // that house the hero reviews and pics
        lastCardIndex: colCount + 3,
      };
    }
  }, [screenSize, colCount]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(
    setup.startSlideIndex,
  );

  const onGoToPreviousSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return setup.lastCardIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [setup.lastCardIndex]);

  const onGoToNextSlide = useCallback(() => {
    setIsSlideshowPaused(true);
    setCurrentSlideIndex(value => {
      if (value === setup.lastCardIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [setup.lastCardIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === setup.lastCardIndex;
  }, [currentSlideIndex, setup.lastCardIndex]);

  const [isSlideshowPaused, setIsSlideshowPaused] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
    onTap: () => {
      setIsSlideshowPaused(true);
    },
  });

  useSetCarouselInterval({
    isSlideshowPaused,
    currentSlideIndex,
    lastSlideIndex: setup.lastCardIndex,
    onSetCurrentSlideIndex: setCurrentSlideIndex,
    time: 2900,
  });

  return (
    <div className="">
      <div className=" text-black mx-8 md:mx-auto w-fit md:flex md:flex-col justify-center items-center">
        <h3 className="text-h3mob lg:text-h3 max-w-[24rem] lg:max-w-max  md:text-center font-title pb-4 md:pb-6">
          Our community loves us
        </h3>
        <StarRating rating={appRating} starColor="yellow" />
      </div>

      <div
        className="md:flex items-center pt-8 md:pt-11 relative"
        {...handlers}>
        <div className="overflow-hidden">
          <div
            className={`flex gap-4 transition ease-out duration-500`}
            style={{
              transform: `${
                isLastSlide
                  ? ''
                  : `translatex(-${currentSlideIndex * setup.cardSize}rem)`
              }`,
            }}>
            <div className="flex flex-col justify-between pl-9 md:pl-4">
              {/* one marked "hero" - this one never moves */}
              {heroSmallReview && (
                <>
                  <ReviewCard
                    title={heroSmallReview[0].title}
                    text={heroSmallReview[0].review}
                    starCount={heroSmallReview[0].starCount}
                    color="bg-sleep"
                  />
                  <img
                    src={small}
                    className={`rounded-[1.5rem] text-black w-[19rem] lg:w-[22rem] h-[16.5rem] object-cover`}
                    draggable="false"
                    alt="a teen girl smiling"
                  />
                </>
              )}
            </div>
            {/* one marked "hero" - this one never moves */}
            {heroLargeReview && (
              <>
                <ReviewCard
                  title={heroLargeReview[0].title}
                  text={heroLargeReview[0].review}
                  starCount={heroLargeReview[0].starCount}
                  color="bg-hair"
                />
                <img
                  src={large}
                  className={`rounded-[1.5rem] text-black w-[19rem] lg:w-[22rem] h-[34rem] object-cover`}
                  draggable="false"
                  alt="one teen girl gives another a piggy back whilst smiling"
                />
              </>
            )}

            <div
              style={{
                display: 'grid',
                gridAutoFlow: 'column dense',
                gridTemplateColumns: `repeat(${colCount}, ${setup.cardSize})`,
                gridTemplateRows: 'repeat(2, 1fr)',
                gap: '1rem',
              }}
              className="h-[34rem]">
              {reviews &&
                reviews.map((review, i) => (
                  <ReviewCard
                    title={review.title}
                    text={review.review}
                    starCount={review.starCount}
                    key={i}
                    className={getGridReference(review.review)}
                    color={getReviewColor(i)}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="absolute top-50% w-full px-11 hidden md:flex md:justify-between ">
          <button onClick={onGoToPreviousSlide}>
            <Icon
              iconName={'left'}
              fill={colors.white}
              height={75}
              width={75}
            />
          </button>
          <button onClick={onGoToNextSlide}>
            <Icon
              iconName={'right'}
              fill={colors.white}
              height={75}
              width={75}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
