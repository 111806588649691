import React, {useCallback, useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {Button, Icon} from '../../components';
import {thinMargins} from '../../constants';
import {ArticleCard} from './ArticleCard';
import {colors} from '../../theme';
import {LearnArticle} from '../../types';
import {usePageWidth, useScreenSize} from '../../utils';

interface IArticleCarousel {
  articles: LearnArticle[];
  title: string;
  subtitle?: string;
  context?: 'articlePage' | 'mainSite' | 'parentArticles' | 'parentPage';
}

export const ArticleCarousel = ({
  articles,
  title,
  subtitle,
  context = 'mainSite',
}: IArticleCarousel) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const screenSize = useScreenSize();
  const pageWidth = usePageWidth();

  const isArticlePage =
    context === 'articlePage' || context === 'parentArticles';

  const setup = useMemo((): {
    transformSize: string;
    marginLeft: number;
    lastSlideIndex: number;
  } => {
    if (screenSize === 'xx-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 23.5}rem`,
        marginLeft: (pageWidth - 1280) / 2,
        lastSlideIndex:
          articles.length > 1 ? articles.length - 2 : articles.length,
      };
    } else if (screenSize === 'extra-large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 23.5}rem`,
        marginLeft: 128,
        lastSlideIndex:
          articles.length > 1 ? articles.length - 2 : articles.length,
      };
    } else if (screenSize === 'large') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 23.5}rem`,
        marginLeft: 128,
        lastSlideIndex:
          articles.length > 1 ? articles.length - 1 : articles.length,
      };
    } else if (screenSize === 'medium') {
      return {
        transformSize: `translatex(-${currentSlideIndex * 19.5}rem)`,
        marginLeft: isArticlePage ? 64 : 32,
        lastSlideIndex:
          articles.length > 1 ? articles.length - 1 : articles.length,
      };
    } else {
      return {
        transformSize: `translatex(-${currentSlideIndex * 19.5}rem)`,
        marginLeft: 32,
        lastSlideIndex: articles.length,
      };
    }
  }, [screenSize, currentSlideIndex, pageWidth]);

  const onGoToPreviousSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === 0) {
        return setup.lastSlideIndex - 1;
      } else {
        return value - 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const onGoToNextSlide = useCallback(() => {
    setCurrentSlideIndex(value => {
      if (value === setup.lastSlideIndex - 1) {
        return 0;
      } else {
        return value + 1;
      }
    });
  }, [setup.lastSlideIndex]);

  const isLastSlide = useMemo(() => {
    return currentSlideIndex === setup.lastSlideIndex;
  }, [currentSlideIndex, setup.lastSlideIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onGoToNextSlide,
    onSwipedRight: onGoToPreviousSlide,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  return (
    <div className="relative">
      {isArticlePage ? (
        <div className={`flex  justify-between pb-6 ${thinMargins}`}>
          <h3 className="text-subtitlelargemob lg:text-subtitlelarge font-title">
            {title}
          </h3>
          <div className="min-w-fit">
            {context === 'articlePage' && (
              <Button
                articleLink={{
                  categoryTitle: title,
                }}
                label={'View all'}
              />
            )}
            {context === 'parentArticles' && (
              <Button
                articleLink={{
                  isParents: true,
                  categoryTitle: title,
                }}
                label={'View all'}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`max-w-screen-xl flex flex-col lg:flex-row justify-center md:items-center lg:items-start md:text-center lg:text-start ${thinMargins} lg:mx-32 pb-8 lg:pb-10`}>
          <div className="lg:w-2/3">
            <h3
              className={`text-h3mob lg:text-h3 font-title text-black pb-2 lg:pb-6`}>
              {title}
            </h3>
            {subtitle && (
              <p className={`text-largebodymob lg:text-largebody text-black`}>
                {subtitle}
              </p>
            )}
          </div>
          {context === 'parentPage' ? (
            <div className={`hidden lg:block lg:ml-auto lg:mr-0 mt-auto mb-0`}>
              <Button label={'View more articles'} linkTo="parents/guides" />
            </div>
          ) : (
            <div className={`hidden lg:block lg:ml-auto lg:mr-0 mt-auto mb-0`}>
              <Button label={'View more articles'} linkTo="articles" />
            </div>
          )}
        </div>
      )}

      <div
        {...handlers}
        style={{
          marginLeft: `${setup.marginLeft}px`,
        }}
        className={`${isArticlePage && 'max-w-[80rem] lg:mr-32'}`}>
        <div
          className={` ${
            isArticlePage
              ? ' overflow-hidden lg:overflow-visible'
              : ' overflow-hidden '
          }`}>
          <div
            className={`flex transition ease-out duration-500 gap-4  h-[24.5rem] lg:h-[27.5rem]`}
            style={{
              transform: `${isLastSlide ? '' : `${setup.transformSize}`}`,
            }}>
            {articles.map((a, i) => (
              <div
                key={i}
                className={` ${
                  isArticlePage
                    ? 'min-w-[18.5rem] w-[18.5rem] lg:min-w-[26rem] lg:max-w-[26rem]'
                    : '  '
                }`}>
                <ArticleCard
                  key={i}
                  title={a.title}
                  subtitle={a.subtitle}
                  slug={a.slug}
                  categoryColor={a.categoryColor}
                  categoryTitle={a.categoryTitle}
                  image={a.image}
                  isFullWidth={isArticlePage ? true : false}
                  isParentsArticle={
                    context === 'parentArticles' || context === 'parentPage'
                  }
                  categories={a.categories}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isArticlePage && (
        <div className="flex lg:hidden justify-center pt-6">
          <Button
            label={'View more articles'}
            linkTo={context === 'parentPage' ? 'parents/guides' : 'articles'}
          />
        </div>
      )}
      <div
        className={` ${
          isArticlePage ? 'lg:hidden' : 'lg:flex'
        } absolute top-[55%] w-full hidden lg:justify-between `}
        style={{
          paddingLeft: `${setup.marginLeft - 100}px`,
          paddingRight: `${setup.marginLeft - 100}px`,
        }}>
        <button onClick={onGoToPreviousSlide}>
          <Icon iconName={'left'} fill={colors.white} height={75} width={75} />
        </button>
        <button onClick={onGoToNextSlide}>
          <Icon iconName={'right'} fill={colors.white} height={75} width={75} />
        </button>
      </div>
    </div>
  );
};
