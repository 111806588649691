import * as React from 'react';
import {Category} from '../../types';
import {Icon, Link} from '../../components';
import {thinMargins} from '../../constants';
import {useState} from 'react';
import {colors} from '../../theme';

interface ICategoryDisplay {
  categories: Category[];
  title: string;
  context: 'questionPage' | 'articlePage' | 'parentArticles';
}

export const CategorySelector = ({
  categories,
  title,
  context,
}: ICategoryDisplay) => {
  const [isOpen, setIsOpen] = useState(false);
  const styling = isOpen ? 'h-full' : 'h-[3rem] lg:h-full overflow-y-hidden';

  return (
    <div className="pb-2 text-black">
      <h3
        className={`${thinMargins} text-subtitlelargemob lg:text-subtitlelarge font-title pb-8 md:text-center lg:text-start`}>
        {title}
      </h3>
      <div
        className={`${styling} flex lg:${thinMargins}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        {context === 'articlePage' ? (
          <div className="flex flex-wrap gap-4 ">
            {categories?.map((category, i) => {
              return (
                <Link
                  articleLink={{
                    categoryTitle: category.title,
                  }}
                  className="min-w-fit"
                  key={i}>
                  <div
                    className="rounded-[1rem] py-3 px-4 text-smallbodymob lg:text-smallbody font-title"
                    key={category.id}
                    style={{backgroundColor: category.color.hex}}>
                    {category.title}
                  </div>
                </Link>
              );
            })}
          </div>
        ) : context === 'questionPage' ? (
          <div className="flex flex-wrap gap-4 ">
            {categories?.map((category, i) => {
              return (
                <Link
                  questionLink={{
                    categoryTitle: category.title,
                  }}
                  className="min-w-fit"
                  key={i}>
                  <div
                    className="rounded-[1rem] py-3 px-4 text-smallbodymob lg:text-smallbody font-title"
                    key={category.id}
                    style={{backgroundColor: category.color.hex}}>
                    {category.title}
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-wrap gap-4 ">
            {categories?.map((category, i) => {
              return (
                <Link
                  articleLink={{
                    isParents: true,
                    categoryTitle: category.title,
                  }}
                  className="min-w-fit"
                  key={i}>
                  <div
                    className="rounded-[1rem] py-3 px-4 text-smallbodymob lg:text-smallbody font-title"
                    key={category.id}
                    style={{backgroundColor: category.color.hex}}>
                    {category.title}
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        <div className="lg:hidden pt-2">
          <Icon
            iconName={isOpen ? 'chevron-up' : 'chevron-down'}
            color={colors.black}
            height={28}
            width={28}
          />
        </div>
      </div>
    </div>
  );
};
