import * as React from 'react';

import {Icon} from '../components/common';
import {findDecimal} from '../utils';

interface IStarRating {
  rating: number;
  mode?: string;
  starColor: 'yellow' | 'black';
  showNumber?: boolean;
  centered?: boolean;
}

export const StarRating = ({
  rating,
  mode = 'dark',
  starColor,
  showNumber = true,
  centered = true,
}: IStarRating) => {
  const numOfFullStars = Math.floor(rating);
  const numOfBlankStars = 5 - numOfFullStars - 1;

  const fullColor = starColor === 'black' ? '#424242' : '#F9D84D';
  const blankColor = starColor === 'black' ? '#4242428a' : '#FFEFAA';

  const fullStars = [];
  for (let i = 0; i < numOfFullStars; i++) {
    fullStars.push(<Icon iconName={'star'} fill={fullColor} key={i} />);
  }

  const blankStars = [];
  for (let i = 0; i < numOfBlankStars; i++) {
    blankStars.push(<Icon iconName={'star'} fill={blankColor} key={i} />);
  }

  return (
    <div className={`flex ${centered ? 'items-center' : 'items-end'} gap-3`}>
      {showNumber && (
        <p
          className={`${
            mode === 'dark' ? 'text-black' : 'text-white'
          } text-caption min-w-fit`}>
          Rated {rating}
        </p>
      )}

      <div className="flex gap-1">
        {fullStars && fullStars}
        {fullStars.length < 5 && (
          <Icon
            iconName={'partial-star'}
            percentageFill={findDecimal(rating)}
            color={fullColor}
            fill={blankColor}
          />
        )}
        {blankStars && blankStars}
      </div>
    </div>
  );
};
