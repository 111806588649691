import * as React from 'react';
import {StaticQuery, graphql} from 'gatsby';

import {Tracker} from '../../module/analytics';
import {
  PremiumFeature,
  PremiumProduct,
  StripePremiumProduct,
} from '../../types';
import bluePremiumShapeImg from '../../images/premium/blue-in-page.png';

async function createCheckoutSession({
  baseUrl,
  webOrigin,
  isLiveMode,
  lookup_key,
  backTo,
}: {
  baseUrl: string;
  webOrigin: string;
  isLiveMode: boolean;
  lookup_key: string;
  backTo: string;
}): Promise<{id: string; url: string}> {
  const resp = await fetch(`${baseUrl}/createCheckoutSession`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isLiveMode,
      webOrigin,
      lookup_key,
      successRedirectPath: '/premium/purchase-successful',
      cancelRedirectPath: backTo,
    }),
  });
  const data = await resp.json();
  return data;
}

function sortByOrder(a: any, b: any) {
  return (a.order ?? 0) - (b.order ?? 0);
}

export const PremiumSectionContainer = ({
  backTo,
  children,
}: {
  backTo: string;
  children: (props: {
    products: Array<PremiumProduct & {stripePrice: StripePremiumProduct}>;
    features: PremiumFeature[];
    creatingCheckout: string;
    onPressProduct: ({lookup_key}: {lookup_key: string}) => void;
  }) => React.ReactNode;
}) => {
  return (
    <StaticQuery<Queries.PremiumSectionQuery>
      query={query}
      render={data => {
        const stripePrices = data.allStripePremiumProduct.nodes;
        const products = data.datoCmsPremium?.products ?? [];
        const features = [...(data.datoCmsPremium?.features ?? [])].sort(
          sortByOrder,
        );

        const productsToShow = products
          .map(p => {
            const stripePrice = stripePrices.find(
              price => price.lookup_key === p?.stripePriceLookupKey,
            );
            return {
              ...p,
              stripePrice,
            };
          })
          .filter(p => !!p.stripePrice)
          .sort(sortByOrder);

        const [creatingCheckout, setCreatingCheckout] = React.useState('');

        const onPressProduct = React.useCallback(
          async ({lookup_key}: {lookup_key: string}) => {
            setCreatingCheckout(lookup_key);
            Tracker.trackInitiateCheckout({productId: lookup_key});

            const session = await createCheckoutSession({
              baseUrl: data.premiumBuildConfig?.firebaseFunctionsEndpoint ?? '',
              isLiveMode: data.premiumBuildConfig?.isLiveMode ?? false,
              lookup_key,
              webOrigin: location.origin,
              backTo,
            });
            // set session ID in storage for later, so we can link back after the purchase
            window.localStorage.setItem('luna_csid', session.id);
            if (session.url) {
              location.href = session.url;
            } else {
              window.alert('Something went wrong.');
            }
          },
          [data, backTo],
        );

        return children({
          products: productsToShow as Array<
            PremiumProduct & {stripePrice: StripePremiumProduct}
          >,
          features: features as PremiumFeature[],
          creatingCheckout,
          onPressProduct,
        });
      }}
    />
  );
};

export const PremiumSectionPageContainer = ({
  children,
}: React.PropsWithChildren<object>) => (
  <div className="bg-sandwisp relative overflow-hidden py-20 md:py-24 lg:py-32">
    <div className="relative z-10">{children}</div>
    <img src={bluePremiumShapeImg} className="absolute -top-60 -right-60" />
    <img
      src={bluePremiumShapeImg}
      className="absolute -bottom-60 -left-60 hidden lg:block"
    />
  </div>
);

const query = graphql`
  query PremiumSection {
    allStripePremiumProduct {
      nodes {
        id
        name
        description
        features
        price
        type
        freeTrialDays
        interval
        lookup_key
      }
    }
    premiumBuildConfig {
      isLiveMode
      firebaseFunctionsEndpoint
    }
    datoCmsPremium {
      id
      features {
        id
        order
        title
        includedInFreeTier
        includedInPremiumTier
        freeTierSpecifics
        premiumTierSpecifics
      }
      products {
        id
        order
        name
        description
        price
        tag
        ctaBuy
        stripePriceLookupKey
        toggleTitle
        toggleContent
        toggleImg {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  }
`;
