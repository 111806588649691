import * as React from 'react';
import {Icon, Link} from '../../components';
import {colors} from '../../theme';
import {truncateString} from '../../utils';

interface IQuestionBreadcrumbs {
  categoryTitle: string;
  questionTitle: string;
}

const RightArrow = () => {
  return <Icon iconName={'right'} fill={colors.background} />;
};

export const QuestionBreadcrumbs = ({
  categoryTitle,
  questionTitle,
}: IQuestionBreadcrumbs) => {
  return (
    <div className="flex flex-wrap pb-6 lg:pb-10 gap-1 text-caption items-center">
      <div className="flex w-max gap-1 items-center">
        <Link linkTo="home">Home</Link>
        <RightArrow />
      </div>
      <div className="flex w-max gap-1 items-center">
        <Link linkTo="questions">Question library</Link>
        <RightArrow />
      </div>
      <div className="flex w-max gap-1 items-center">
        <Link
          questionLink={{
            categoryTitle: categoryTitle,
          }}>
          {categoryTitle}
        </Link>
        <RightArrow />
      </div>
      <div className="w-max">
        <Link
          questionLink={{
            categoryTitle: categoryTitle,
            questionTitle: questionTitle,
          }}>
          {truncateString(questionTitle, 50)}
        </Link>
      </div>
    </div>
  );
};
