import * as React from 'react';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';

import {Button, Link} from '../../components';
import {ButtonTracking} from '../../types';
import {Tracker} from '../../module/analytics';
import {appStoreLink, playStoreLink} from '../../constants';
import {StarRating} from '../StarRating';
import playStore from '../../images/play-badge-1.png';
import appStore from '../../images/app-badge-1.png';
import cardPic from '../../images/parents/parent-header3.png';

export const ContentCard = ({
  text,
  color,
  buttonCta,
  buttonUrl,
  image,
  trackingId,
}: {
  text: string;
  color?: string;
  buttonCta?: string;
  buttonUrl?: string;
  image?: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  trackingId?: string;
}) => {
  return (
    <div
      className=" relative my-4 mx-auto rounded-[2rem] sm:flex-row flex gap-8 px-8 sm:px-0"
      style={{backgroundColor: color ? color : '#E68E86'}}>
      <div className="flex flex-col justify-center items-center sm:items-start gap-4 w-full sm:w-7/12 sm:ml-12 py-8">
        <StarRating
          rating={4.8}
          mode="dark"
          starColor={'yellow'}
          centered={false}
        />
        <p className="text-subtitlesmall lg:text-subtitlesmall font-semibold text-center sm:text-left">
          {text}
        </p>
        {buttonCta && buttonUrl ? (
          <div className="">
            <Button
              label={buttonCta}
              type="secondary"
              externalLinkUrl={buttonUrl}
              onClick={() => {
                trackingId &&
                  Tracker.trackContentCardClick({articleId: trackingId});
              }}
            />
          </div>
        ) : (
          <div className="flex max-w-[280px] gap-4">
            <Link
              href={appStoreLink}
              external
              tracking={ButtonTracking.AppStoreDownload}>
              <img src={appStore} alt="the apple app store logo" />
            </Link>
            <Link
              href={playStoreLink}
              external
              tracking={ButtonTracking.AppStoreDownload}>
              <img src={playStore} alt="the google play store logo" />
            </Link>
          </div>
        )}
      </div>
      <div className="hidden sm:block w-0 sm:w-5/12 max-h-[18rem]">
        {image ? (
          <GatsbyImage
            alt={image?.alt || ''}
            image={image.gatsbyImageData}
            className="h-full w-full object-cover rounded-r-[2rem] object-center"
          />
        ) : (
          <img
            src={cardPic}
            className="h-full w-full object-cover rounded-r-[2rem] object-top"
            alt="A mother happy that their teen discovered luna"
          />
        )}
      </div>
    </div>
  );
};
