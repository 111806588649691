import React, {useState} from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {wideMargins} from '../../constants';
import {StyledStructuredText} from '../../components/common/StyledStructuredText';
import {Feature} from '../../types';
import {StructuredTextDocument} from 'react-datocms/*';

interface IHorizontalTabs {
  title: string;
  activeColor?: 'blue' | 'yellow';
  tabs: Feature[];
}

export const HorizontalTabs = ({
  title,
  tabs,
  activeColor = 'blue',
}: IHorizontalTabs) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeStyle = activeColor === 'blue' ? 'bg-bluerice' : 'bg-sandwisp';
  const inactiveStyle = 'bg-none';

  const activeTab = tabs[activeTabIndex];
  const image = activeTab?.image && getImage(activeTab.image);

  return (
    <div className={` text-black gap-5 ${wideMargins}`}>
      <h3 className="text-h3mob lg:text-h3 font-title text-center pb-6">
        {title}
      </h3>
      <div className="hidden lg:flex flex-row justify-center items-center">
        {tabs.map((tab, i) => (
          <div
            className={`cursor-pointer rounded-[1.8rem] py-3 px-6 min-w-fit ${
              i === activeTabIndex ? activeStyle : inactiveStyle
            }`}
            onClick={() => {
              setActiveTabIndex(i);
            }}
            key={i}>
            <p className="text-black text-smallbody font-semibold">
              {tab.title}
            </p>
          </div>
        ))}
      </div>
      <div className="hidden lg:flex flex-row lg:justify-center h-auto pt-8 gap-12 items-center ">
        <div className=" lg:w-1/2 2xl:w-1/2 text-center lg:text-start ">
          <p className="text-subtitlesmall font-semibold pb-5">
            {activeTab?.description}
          </p>
          {activeTab.explainer?.value !== null && (
            <div className="hidden lg:block text-largebody">
              <StyledStructuredText
                data={activeTab.explainer as unknown as StructuredTextDocument}
              />
            </div>
          )}
        </div>
        {image && (
          <GatsbyImage
            class="min-h-[18rem] max-h-[20rem] min-w-[18rem] max-w-[20rem] rounded-[2rem]"
            alt={
              activeTab.image?.alt ||
              `an image representing ${activeTab?.title}`
            }
            image={image}
          />
        )}
      </div>
    </div>
  );
};
