import * as React from 'react';
import {Link} from '../../components/common';
import marketingCard from '../../images/homepage/marketing-card.png';
import aotd from '../../images/app-ot-day-light.png';
import playStore from '../../images/play-badge-1.png';
import appStore from '../../images/app-badge-1.png';
import {appStoreLink, playStoreLink} from '../../constants';
import {ButtonTracking} from '../../types';

export const MarketingCard = ({tagline}: {tagline: string}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${marketingCard})`,
      }}
      className="h-full flex justify-center items-center rounded-[2rem] max-w-full bg-cover">
      <div className="w-[80%] text-center lg:text-left">
        <img
          src={aotd}
          alt="apple's app of the day logo"
          className="m-auto lg:m-0"
        />
        <h4 className="text-white text-h3mob lg:text-h3 font-semibold pt-4 pb-5">
          {tagline}
        </h4>
        <div className="flex gap-4 pt-4 lg:pt-6 justify-center lg:justify-start h-[4rem] lg:h-[5rem]">
          <Link
            href={appStoreLink}
            external
            tracking={ButtonTracking.AppStoreDownload}>
            <img
              src={appStore}
              className="hover:opacity-90 max-h-[100%]"
              alt="Apple app store button"
            />
          </Link>
          <Link
            href={playStoreLink}
            external
            tracking={ButtonTracking.AppStoreDownload}>
            <img
              src={playStore}
              className="hover:opacity-90 max-h-[100%]"
              alt="Google play store button"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
