import React from 'react';

import {Icon, Link, Tag} from '../../components/common';
import {Category} from '../../types';
import {colors} from '../../theme';

interface IQuestionCard {
  question: string;
  categories: Category[];
  categoryTitle: string;
  isQuestionCategoryPage?: boolean;
}

export function QuestionCard({
  question,
  categories,
  categoryTitle,
  isQuestionCategoryPage = false,
}: IQuestionCard) {
  const scrollLength =
    categories.length > 1
      ? 'max-h-[18rem] lg:max-h-[20rem] '
      : 'max-h-[20rem] lg:max-h-[22rem] ';

  return (
    <div className=" max-w-screen-xl flex justify-center">
      <div
        className={`${
          isQuestionCategoryPage ? 'md:w-[25rem]' : 'w-[17.6rem] lg:w-[25rem]'
        }`}>
        <div
          className={`relative rounded-[1rem] bg-white py-6 md:py-8 px-6 md:px-7 ${
            isQuestionCategoryPage
              ? 'h-fit pb-20 sm:h-[31rem] sm:pb-0'
              : 'h-[31rem]'
          } text-black border-lightgrey border-[0.76px]`}>
          <div className="pb-4 flex flex-wrap flex-row gap-2">
            {categories &&
              categories.map((c, i) => (
                <Tag color={c.color.hex} title={c.title} key={i} />
              ))}
          </div>
          <div
            className={`text-subtitlesmallmob font-semibold lg:text-subtitlesmall ${scrollLength} lg:max-h-fill overflow-y-auto`}>
            Q: {question}
          </div>
          <div className="absolute bottom-2 left-6 right-6">
            <Link
              questionLink={{
                categoryTitle: categoryTitle,
                questionTitle: question,
              }}>
              <div className="flex text-primaryred py-4 justify-end hover:font-semibold">
                <p>View answer</p>
                <Icon iconName={'right'} color={colors.primaryred} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
