import * as React from 'react';
import {appStoreLink, playStoreLink} from '../../constants';
import {QRCard} from '../QRCard';
import {Link} from '../../components';
import playStore from '../../images/play-badge-1.png';
import appStore from '../../images/app-badge-1.png';
import groupPic from '../../images/teen-group.png';
import {ButtonTracking} from '../../types';

export const ArticleDownloadCard = ({
  starRating,
  cta,
}: {
  starRating: number;
  cta: string;
}) => {
  return (
    <div className="h-[15rem] lg:h-[20.8rem] relative mt-8 lg:mt-10 md:max-w-[28rem] lg:max-w-none mx-auto">
      <div className="h-[15rem] lg:h-[20.8rem] ">
        <img
          src={groupPic}
          className="h-full w-full object-cover rounded-[2rem] object-top"
          alt="a group of girls standing together with their arms around eachother, smiling"
        />
      </div>
      <div className="absolute top-0 h-[15rem] lg:h-[20.8rem] flex flex-col justify-center items-center gap-4 w-full">
        <p className="text-subtitlesmall lg:text-subtitlesmall font-semibold text-center text-white mx-8">
          {cta}
        </p>
        <div className="w-[22.7rem] hidden lg:block mx-auto">
          <QRCard appRating={starRating} />
        </div>
        <div className="flex lg:hidden max-w-[280px] gap-4 p-4">
          <Link
            href={appStoreLink}
            external
            tracking={ButtonTracking.AppStoreDownload}>
            <img src={appStore} alt="the apple app store logo" />
          </Link>
          <Link
            href={playStoreLink}
            external
            tracking={ButtonTracking.AppStoreDownload}>
            <img src={playStore} alt="the google play store logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};
